/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Button, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import { exportCsv } from '../../component/helpers/utility';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import TGTextField from '../../component/Elements/TGTextField';
import RegistNewButton from '../../component/Form/RegistNewButton';
import DownloadButton from '../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../component/Elements/TGGrid';
import hasRole from '../../lib/hasRole';
import OverHideText from '../../component/Elements/OverHideText';
import { cancelVersion, resetCancelVersion, searchVersions } from '../../state/actions/version';

const localStorageKey = 'searchVersions';

export default function VersionSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit, setValue, reset, getValues } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.version);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const hasAdmin = hasRole('systemAdmin', user);
  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchVersions, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const [isCancelEditMode, setCancelEditMode] = useState<boolean>(false);
  const [isResetCancelEditMode, setResetCancelEditMode] = useState<boolean>(false);
  const [editVersionId, setEditVersionId] = useState<string>('');

  const editCancelVersion = async (versionId: string) => {
    setCancelEditMode(true);
    setResetCancelEditMode(false);
    setEditVersionId(versionId);
  };
  const cancelCancelEditMode = () => {
    setCancelEditMode(false);
    setEditVersionId('');
  };
  const doneCancelEditMode = async () => {
    await start(cancelVersion, { versionId: editVersionId, noLoading: true }, dispatch);
    setCancelEditMode(false);
    setEditVersionId('');
    handleSubmit(searchSubmit)();
  };

  const editResetCancelVersion = async (versionId: string) => {
    setResetCancelEditMode(true);
    setCancelEditMode(false);
    setEditVersionId(versionId);
  };

  const cancelResetCancelEditMode = () => {
    setResetCancelEditMode(false);
    setEditVersionId('');
  };
  const doneResetCancelEditMode = async () => {
    try {
      await start(resetCancelVersion, { versionId: editVersionId, noLoading: true }, dispatch);
      setResetCancelEditMode(false);
      setEditVersionId('');
      handleSubmit(searchSubmit)();
    } catch (error: any) {
      if (error.status === 401) {
        // eslint-disable-next-line no-alert
        alert(t(error.data.message));
      } else if (error.status === 400) {
        const err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[^0-9]/g, '');
        // eslint-disable-next-line no-alert
        alert(t(err));
      } else {
        // eslint-disable-next-line no-alert
        alert(t('common.msg.erros'));
      }
    }
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/version/export?releaseDateFrom=${encodeURIComponent(data.releaseDateFrom)}&releaseDateTo=${encodeURIComponent(data.releaseDateTo)}&locale=${sessionStorage.getItem('locale') === 'cn' ? 'zh-Hans' : sessionStorage.getItem('locale')}`;
    exportCsv(url, 'Version');
  };

  useEffect(() => {
    handleSearch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SearchForm title={t('versionSearch.label.top')} description={t('versionSearch.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={3}>
            <TGTextField
              label={t('versionSearch.label.releaseDateFrom')}
              type="date"
              registration={register('releaseDateFrom')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField
              label={t('versionSearch.label.releaseDateTo')}
              type="date"
              registration={register('releaseDateTo')}
            />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid
        item
        xs={12}
        sx={{ float: 'right', mb: 2 }}
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin, roles.groupAdmin]}
      >
        {hasAdmin && (
          <RegistNewButton
            handleClick={() => {
              history(routes.versionCreate);
            }}
          />
        )}
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.versions}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'versionNumberId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'versionNumber':
                return (
                  <Link to={routes.versionEdit.replace(':id', row.versionId)}>
                    <OverHideText>{value}</OverHideText>
                  </Link>
                );
              case 'versionReleaseDate':
                return `${moment(row.versionReleaseDate).format('YYYY/MM/DD')}`;
              case 'titleJaVer':
                return (
                  <Link to={routes.versionEdit.replace(':id', row.versionId)}>
                    <OverHideText>{row.titleJaVer}</OverHideText>
                  </Link>
                );
              case 'titleEngVer':
                return (
                  <Link to={routes.versionEdit.replace(':id', row.versionId)}>
                    <OverHideText>{row.titleEngVer}</OverHideText>
                  </Link>
                );
              case 'versionRegisterPerson':
                return `${row.versionRegUser}`;
              case 'versionRegisterTime':
                return `${row.versionRegDate}`;
              case 'versionCancelDate':
                return row.versionCancelDate ? `${moment(row.versionCancelDate).format('YYYY/MM/DD')}` : '';
              case 'cancelAction':
                if (isCancelEditMode && editVersionId === row.versionId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editCancelVersion(row.versionId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      row.versionCancelDate != null
                      || (isCancelEditMode && editVersionId !== row.versionId)
                    }
                  >
                    {t('versionSearch.label.cancel')}
                  </Button>
                );

              case 'resetCancelAction':
                if (isResetCancelEditMode && editVersionId === row.versionId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneResetCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelResetCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editResetCancelVersion(row.versionId)}
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={
                      row.versionCancelDate == null
                      || (isResetCancelEditMode && editVersionId !== row.versionId)
                    }
                  >
                    {t('common.btn.cancel')}
                  </Button>
                );
              default:
                return <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
