/* eslint-disable max-len */
import { Typography, Divider, List, ListItem, ListItemText, Button, Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import { RegisterInformation } from '../register/types';
import routes from '../../../constants/routes';
import TGTextField from '../../../component/Elements/TGTextField';
import TGGrid from '../../../component/Elements/TGGrid';
import { getLicensesTrialByUser, getRenewalDate, getStatusLicensesByUserLogin } from '../../../state/actions/license';
import DialogConfirmCancel from '../confirmCancel';
import PurchaseForm from '../../../component/Layout/PurchaseForm';
import StepPurchase from '../stepPurchase';
import { getPriceLicense } from '../../../state/actions/price';

const currentDate = new Date();
const formatCurrentDate = currentDate.toISOString().slice(0, 10);

// set current date in validate
const currentDateCustomHour = new Date();
currentDateCustomHour.setHours(0, 0, 0, 0);


const monthDiff = (startDate: Date, endDate: Date) => {
  let months;
  if (startDate.getMonth() > endDate.getMonth() || startDate.getFullYear() > endDate.getFullYear()) {
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
  } else {
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months += endDate.getMonth() - startDate.getMonth();
  }
  const selectedDateTime = new Date(startDate.setHours(0, 0, 0, 0));
  selectedDateTime.setMonth(selectedDateTime.getMonth() + months);
  endDate.setHours(0, 0, 0, 0);
  if (months < 13) {
    if (selectedDateTime < endDate) {
      months += 1;
    }
    return months;
  }
  return months - 1;
};

export default function BuyAdditionalLicenses() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const param = useParams();
  const rows = useSelector((state: RootStateOrAny) => state.license);
  const [totalAmount, setTotalAmount] = useState('0');
  const [open, setOpen] = useState(false);
  const { renewalDate, endDateTrail } = rows;
  const endDate = new Date(renewalDate);
  const [calculatedMoney, setCalculatedMoney] = useState(0);
  const [unitAmount, setUnitAmount] = useState(0);
  const [monthsOfUse, setMonthsOfUse] = useState(12);

  const endDateTrial = new Date(endDateTrail);
  endDateTrial.setHours(0, 0, 0, 0);

  const startDateAfterTrial = moment(endDateTrial).format('YYYY-MM-DD');
  const startDateAfterTrialDisplay = moment(endDateTrial).format('YYYY/MM/DD');

  const maxStartDate = new Date(renewalDate);
  maxStartDate.setDate(maxStartDate.getDate() - 1);

  useEffect(() => {
    const fetchData = async () => {
      if (param.userId) {
        await start(getLicensesTrialByUser, { userId: param.userId }, dispatch);
        start(getStatusLicensesByUserLogin, null, dispatch);
        setCalculatedMoney(unitAmount * Number(rows.licensesTrialByUser.length));
        setTotalAmount(calculatedMoney.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
        await start(getRenewalDate, { userId: param.userId }, dispatch);
      }
    };
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, param.userId]);
  // ================================================ LOCAL STATE AREA ========================================= //

  const selectedEndDate = `${endDate.getFullYear()}/${(endDate.getMonth() + 1).toString().padStart(2, '0')}/${endDate
    .getDate()
    .toString()
    .padStart(2, '0')}`;
  // ================================================ END LOCAL STATE AREA ========================================= //

  // mouse event in dialog
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.purchase);
    }
  };

  // validate form input
  const schemaInput = yup.object({
    amountOfLicense: rows.licensesTrialByUser.length > 0 ?
      yup.number()
        .typeError(t('purchase.msg.requiredAmountOfLicense'))
        .required(t('purchase.msg.requiredAmountOfLicense'))
        .min(0)
        .max(100, t('purchase.msg.requiredAmountOfLicenseMax'))
      : yup.number()
        .typeError(t('purchase.msg.requiredAmountOfLicense'))
        .required(t('purchase.msg.requiredAmountOfLicense'))
        .min(1, t('purchase.msg.requiredAmountOfLicenseMin'))
        .max(100, t('purchase.msg.requiredAmountOfLicenseMax')),
    startDate: yup
      .date()
      .required(t('licenseCreate.msg.requiredStartDate'))
      .min(currentDateCustomHour, t('purchase.msg.invalidStartDate'))
      .max(maxStartDate, t('purchase.msg.invalidStartDate'))
      .typeError(t('purchase.msg.invalidStartDate')),
  });

  // form stuffs
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<RegisterInformation>({
    resolver: yupResolver(schemaInput),
  });

  const watchedStartDate = watch('startDate');

  const [dataPrice, setDataPrice] = useState({
    price: 0,
    priceLicenseMonth: '',
    availableDeviceNumber: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res : any = await start(getPriceLicense, null, dispatch);
        if (res instanceof Object) {
          setDataPrice(res);
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    };
    fetchData();
  }, [dispatch]);

  const priceLicenseMonthCompanyArray = dataPrice.priceLicenseMonth
    .split(',')
    .map((item) => parseInt(item, 10));

  const watchedAmountOfLicense = watch('amountOfLicense');
  useEffect(() => {
    if (watchedStartDate) {
      const numberMonth = monthDiff(new Date(watchedStartDate), endDate);
      setMonthsOfUse(numberMonth);
      setUnitAmount(priceLicenseMonthCompanyArray[numberMonth - 1]);
      const total = priceLicenseMonthCompanyArray[numberMonth - 1] * (Number(watchedAmountOfLicense > 0 ? watchedAmountOfLicense : 0) + Number(rows.licensesTrialByUser.length));
      setCalculatedMoney(total);
      setTotalAmount(total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedStartDate, watchedAmountOfLicense, endDate]);

  const [showErrors, setShowErrors] = useState(true);

  // mouse event in dialog
  const handleOpenDialogPaymentMethod = () => {
    if (param.userId) {
      const endDateObj = new Date(endDate);
      const price = dataPrice.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      const extensionLicenses = {
        userId: param.userId,
        totalAmount,
        unitAmount,
        AmountOfLicenseTrial: Number(rows.licensesTrialByUser.length),
        amountOfLicense: Number(watchedAmountOfLicense),
        ModeSubscription: true,
        endDate: rows.licenseCount === 0 ? moment(watchedStartDate).add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD') : moment(endDateObj).format('YYYY-MM-DD'),
        startDate: currentDate < endDateTrial ? startDateAfterTrial : watchedStartDate,
        monthsOfUse,
        price,
      };
      localStorage.setItem('extensionLicenses', JSON.stringify(extensionLicenses));
      localStorage.setItem('userId', param.userId);
      window.location.href = `/purchase/addLicenseConfirm/${param.userId}`;
    }
  };
  // event change language, turn off errors message
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  const tabs = [
    {
      title: t('purchaseStepBuyAdditionalLicenses'),
    },
    {
      title: t('purchaseStepInputPaymentInfo'),
    },
    {
      title: t('purchaseStepLastestConfirm'),
    },
    {
      title: t('purchaseStepBuyAdditionalLicensesSuccess'),
    },
  ];

  // show errors when click button submit
  const handleFormSubmit = () => {
    setShowErrors(true);
    handleSubmit(handleOpenDialogPaymentMethod)();
  };

  useEffect(() => {
    const result = localStorage.getItem('extensionLicenses');
    if (result) {
      const data = JSON.parse(result);
      setValue('startDate', data.startDate);
      setValue('amountOfLicense', data.amountOfLicense);
    }
    localStorage.removeItem('extensionLicenses');
  }, [setValue]);

  let purchaseDetailsDescription = t('purchaseDetailsDescription1');
  purchaseDetailsDescription = purchaseDetailsDescription.replace('{price_license}', dataPrice.price.toLocaleString());

  let purchaseDetailsDescription3 = t('purchaseDetailsDescription3');
  purchaseDetailsDescription3 = purchaseDetailsDescription3.replace('{number_device}', dataPrice.availableDeviceNumber.toString());

  return (
    <div className="purchase__container">
      <PurchaseForm>
        <StepPurchase tabs={tabs} idx={0} />
        <Typography variant="h3" mt={3} mb={1}>
          {t('purchase.buyAdditionalLicenses.label.top')}
        </Typography>
        <Typography variant="h5" mb={2} color="inherit">
          {t('purchase.buyAdditionalLicenses.label.decription')}
        </Typography>
        <Typography variant="h4" mt={3} mb={1}>
          {t('purchase.buyAdditionalLicenses.detailBuy')}
        </Typography>
        <Divider sx={{ borderBottom: '2px solid' }} />
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          {rows.licensesTrialByUser.length > 0 && (
            <TGGrid item md={2} xs={3}>
              <Typography variant="body1" className="fs-09-rem">
                {t('purchase.title.FreeTrialLicenseNumber')}
              </Typography>
            </TGGrid>
          )}
          {rows.licensesTrialByUser.length > 0 && (
            <TGGrid item md={10} xs={9}>
              <TGGrid item md={1} xs={8} />
              <Typography variant="body1" className="fs-09-rem">
                {rows.licensesTrialByUser.length}
              </Typography>
            </TGGrid>
          )}
          {rows.licensesTrialByUser.length > 0 && (
            <TGGrid item md={2} xs={3}>
              {}
            </TGGrid>
          )}
          {rows.licensesTrialByUser.length > 0 && (
            <TGGrid item md={10} xs={9}>
              <List>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchase.buyAdditionalLicenses.numberOfLicenses.description')} />
                </ListItem>
              </List>
            </TGGrid>
          )}
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchase.buyAdditionalLicenses.numberOfLicenses')}
            </Typography>
          </TGGrid>
          {rows.licensesTrialByUser.length > 0 ? (
            <TGGrid item md={10} xs={9}>
              <TGGrid item md={1} xs={8}>
                <TGTextField
                  fullWidth
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === 'e' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  registration={register('amountOfLicense')}
                  error={showErrors ? 'amountOfLicense' in errors : false}
                  inputProps={{
                    min: 0,
                    max: 100,
                  }}
                  label=""
                  variant="outlined"
                />
              </TGGrid>
              {errors.amountOfLicense && (
              <TGGrid item xs={12} className="text-error">
                {showErrors ? errors.amountOfLicense.message : ''}
              </TGGrid>
              )}
            </TGGrid>
          ) : (
            <TGGrid item md={10} xs={9}>
              <TGGrid item md={1} xs={8}>
                <TGTextField
                  fullWidth
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === ',' || e.key === '.' || e.key === '-' || e.key === 'e' || e.key === '+') {
                      e.preventDefault();
                    }
                  }}
                  registration={register('amountOfLicense')}
                  error={showErrors ? 'amountOfLicense' in errors : false}
                  inputProps={{
                    min: 1,
                    max: 100,
                  }}
                  label=""
                  variant="outlined"
                />
              </TGGrid>
              {errors.amountOfLicense && (
              <TGGrid item xs={12} className="text-error">
                {showErrors ? errors.amountOfLicense.message : ''}
              </TGGrid>
              )}
            </TGGrid>
          )}
          <TGGrid item md={2} xs={3}>
            {}
          </TGGrid>
          <TGGrid item md={10} xs={9}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={purchaseDetailsDescription} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription2')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={purchaseDetailsDescription3} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsDescription4')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDetailsbuyAdditionalLicensesDescription5')} />
              </ListItem>
            </List>
          </TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseDateStartLicense2')}
            </Typography>
          </TGGrid>
          {currentDate < endDateTrial ? (
            <TGGrid item md={5} xs={8} mb={1}>
              <Typography variant="body1" className="fs-09-rem">
                {startDateAfterTrialDisplay}
              </Typography>
            </TGGrid>
          ) : (
            <TGGrid item md={5} xs={8} mb={1}>
              <TGTextField
                fullWidth
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                registration={register('startDate')}
                defaultValue={formatCurrentDate}
                error={showErrors ? 'startDate' in errors : false}
                helperText={showErrors ? errors.startDate?.message : ''}
                label=""
                variant="outlined"
              />
            </TGGrid>
          )}
        </TGGrid>
        {rows.licensesTrialByUser.length > 0 && (
        <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
          <TGGrid item md={2} xs={3}>
            {}
          </TGGrid>
          <TGGrid item md={10} xs={9}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDateStartBuyAdditionalDescription1')} />
              </ListItem>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('purchaseDateStartBuyAdditionalDescription2')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
        )}

        <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseDateEndLicense')}
            </Typography>
          </TGGrid>
          {(rows.licenseCount === 0) ? (
            <TGGrid item md={5} xs={8}>
              <Typography variant="body1" className="fs-09-rem">
                {moment(watchedStartDate).add(1, 'years').subtract(1, 'days').format('YYYY/MM/DD')}
              </Typography>
            </TGGrid>
          ) : (
            <TGGrid item md={5} xs={8}>
              <Typography variant="body1" className="fs-09-rem">
                {selectedEndDate}
              </Typography>
            </TGGrid>
          )}
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
          <TGGrid item md={2} xs={3}>
            {}
          </TGGrid>

          {rows.licensesTrialByUser.length > 0 ? (
            <TGGrid item md={10} xs={9}>
              <List>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchaseDateEndBuyAdditionalDescriptionFirst')} />
                </ListItem>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchaseDateEndBuyAdditionalDescriptionLast')} />
                </ListItem>
              </List>
            </TGGrid>
          ) : (
            <TGGrid item md={10} xs={9}>
              <List>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchaseDateEndBuyAdditionalDescription1')} />
                </ListItem>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchaseDateEndBuyAdditionalDescription2')} />
                </ListItem>
                <ListItem sx={{ padding: '0' }}>
                  <ListItemText primary={t('purchaseDateEndBuyAdditionalDescription3')} />
                </ListItem>
              </List>
            </TGGrid>
          )}
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchasePaymentAmount')}
            </Typography>
          </TGGrid>
          <TGGrid item md={2} xs={8}>
            <Typography variant="body1" className="fs-09-rem">
              {totalAmount}
              &nbsp;
              {t('yenUnit')}
            </Typography>
          </TGGrid>
        </TGGrid>
        <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
          <TGGrid item md={8} xs={11}>
            <Box display="flex" alignItems="center" justifyContent="right">
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleOpenDialog}
              >
                {t('purchaseEmail.button.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: 'auto', fontSize: '14px' }}
                onClick={handleFormSubmit}
              >
                {t('addPurchaseButton')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
        <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
      </PurchaseForm>
    </div>
  );
}
