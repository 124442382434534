/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Checkbox, Box, Button, DialogContentText, DialogContent, Dialog, DialogActions } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import moment from 'moment';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { getGroups } from '../../state/actions/group';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './typePayment';
import TGGrid from '../../component/Elements/TGGrid';
import { mailLicensesPayment, sendMailLicensePayment } from '../../state/actions/license';
import OverHideText from '../../component/Elements/OverHideText';
import TGTextField from '../../component/Elements/TGTextField';

const localStorageKey = 'searchLicenseMailPayment';

export default function MailLicensePayment() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, rowId: string) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };
  const isAtLeastOneCheckboxChecked = selectedRows.length > 0;
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const mailLicensePayment = async () => {
    handleClose();
    try {
      const extensionLicenses = {
        ListInfomationPurchase: selectedRows,
      };
      await start(sendMailLicensePayment, extensionLicenses, dispatch);
      handleSubmit(searchSubmit)();
      setSelectedRows([]);
    } catch (error: any) {
      // eslint-disable-next-line no-empty
    }
  };

  const { register, handleSubmit, setValue, reset, getValues } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(mailLicensesPayment, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  return (
    <SearchForm title={t('licenseMail.label.top')} description={t('descriptionSendMailPaymentSuccess')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin, roles.groupAdmin]}>
        <SearchCriteria
          handleRefresh={() => {
            reset(SearchCriteriaDefaultInput);
            const searchLocalStorage = localStorage.getItem(localStorageKey);
            if (searchLocalStorage) {
              localStorage.removeItem(localStorageKey);
            }
          }}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('LoginId')} label={t('common.label.userid')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('GroupCode')} label={t('common.label.groupid')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('UserName')} label={t('common.label.username')} />
          </TGGrid>
          <TGGrid item xs={12} sm={3}>
            <TGTextField registration={register('MailRegister')} label={t('common.label.email')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.licenses}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'infomationPurchaseId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'userName':
                return (row.userName);
              case 'loginId':
                return (row.loginId);
              case 'groupCode':
                return (row.groupCode);
              case 'quantity':
                return (row.quantity);
              case 'totalAmount':
                return row.totalAmount != null ? row.totalAmount.toLocaleString() : '0';
              case 'countSendMailPayment':
                return (row.countSendMailPayment);
              case 'mailRegister':
                return (row.mailRegister);
              case 'startTimeLicense':
                return moment.utc(row.startTimeLicense).format('YYYY/MM/DD');
              case 'mailLicense':
                return (
                  <Checkbox
                    checked={selectedRows.includes(row.infomationPurchaseId)}
                    onChange={(event) => handleRowCheckboxChange(event, row.infomationPurchaseId)}
                    color="primary"
                  />
                );
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
      <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
        <TGGrid item md={8} sm={11}>
          <Box display="flex" alignItems="center" justifyContent="right">
            <Button
              variant="contained"
              sx={{ marginLeft: '20px', width: 'auto', fontSize: '14px' }}
              onClick={handleClickOpen}
              disabled={!isAtLeastOneCheckboxChecked}
            >
              {t('mailLicenseBtn')}
            </Button>
          </Box>
        </TGGrid>
      </TGGrid>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <DialogContentText>{t('sendMailPaymentSuccess')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained" autoFocus>{t('btnNo')}</Button>
          <Button onClick={mailLicensePayment} variant="contained" autoFocus>{t('btnYes')}</Button>
        </DialogActions>
      </Dialog>
    </SearchForm>
  );
}
