/* eslint-disable max-len */
import { Typography, Divider, List, ListItem, ListItemText, Button, Box, Alert } from '@mui/material';
import { useState, useEffect, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { start } from '../../../lib/saga-promise';
import { RegisterInformation } from './types';
import TGTextField from '../../../component/Elements/TGTextField';
import TGGrid from '../../../component/Elements/TGGrid';

import ICountryDdl from '../../../modal/ICountryDdl';
import CountrySelector from '../../../component/Form/CountrySelector';
import { getCountries } from '../../../state/actions/country';
import routes from '../../../constants/routes';
import DialogConfirmCancel from '../confirmCancel';
import IsPublicationCorporateNameRadio from '../../../component/Form/IsPublicationCorporateNameRadio';

/**
 * Calculate licesne expired data
 * @param date Use desired start date
 * @returns Expiration Date 1, Expiration Date 2
 */
const performCalculation = (date: string) => {
  if (date) {
    const selectedDateTime = new Date(date);
    selectedDateTime.setDate(selectedDateTime.getDate() + 29);
    const formattedEndDate1 = `${selectedDateTime.getFullYear()}
      /${(selectedDateTime.getMonth() + 1).toString().padStart(2, '0')}
      /${selectedDateTime.getDate().toString().padStart(2, '0')}`;

    selectedDateTime.setDate(selectedDateTime.getDate());
    selectedDateTime.setFullYear(selectedDateTime.getFullYear() + 1);
    const formattedEndDate2 = `${selectedDateTime.getFullYear()}
      /${(selectedDateTime.getMonth() + 1).toString().padStart(2, '0')}
      /${selectedDateTime.getDate().toString().padStart(2, '0')}`;

    return [formattedEndDate1, formattedEndDate2];
  }
  return [];
};

/**
 * Component Input Interface
 */
type Props = {
  licensePurchaser: string;
  mailRegister: string;
  formData: any;
  setFormData: React.Dispatch<SetStateAction<any>>;
  dataCompany: any;
};

// use to detect current form type
const purchaseType = 'Trial';

// get current date
const currentDate = new Date();

// default value display for Use desired start date
const formatCurrentDate = currentDate.toISOString().slice(0, 10);

// calculate default value for Expiration Date 1, Expiration Date 2
const [formattedEndDate1, formattedEndDate2] = performCalculation(formatCurrentDate);

// set current date in validate
const currentDateCustomHour = new Date();
currentDateCustomHour.setHours(0, 0, 0, 0);

/**
 * Main component
 */
export default function InformationCompanyTrial({ mailRegister, licensePurchaser, formData, setFormData, dataCompany }: Props) {
  // hooks
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [codeError, setCodeError] = useState<string>('');
  // ================================================ LOCAL STATE AREA ========================================= //
  const [selectedEndDate1, setSelectedEndDate] = useState(formattedEndDate1);
  const [selectedEndDate2, setSelectedEndDate1] = useState(formattedEndDate2);
  const [open, setOpen] = useState(false);

  // get list countries from global state
  const countries: ICountryDdl[] = useSelector((state: RootStateOrAny) => state.country.allCountries);

  // convert global state countries to display model
  let country = countries.map(({ countryId, countryNameJp }) => ({ countryId, countryNameJp }));
  if (country.length < 1) {
    country = [
      {
        countryId: 'JPN',
        countryNameJp: '日本',
      },
    ];
  }

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
  }, [dispatch]);

  // ================================================ END LOCAL STATE AREA ========================================= //

  // use for backend as required model
  const totalAmount = '0';

  // mouse event in dialog
  const handleOpenDialog = () => {
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const handleConfirmation = (confirm: boolean) => {
    if (confirm) {
      navigate(routes.purchase);
    }
  };

  // validate form input
  const schemaInputTrial = yup.object({
    countryId: yup.string().required(t('groupCreate.msg.requiredCountryId')),
    companyName: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredCompanyName'))
      .max(256, t('purchase.msg.requiredCompanyName')),
    companyNameFurigana: currentLanguage === 'ja' ?
      yup.string()
        .trim()
        .matches(/^[\u30A0-\u30FF\s]+$/, t('purchase.msg.matchedKatakana'))
        .required(t('purchase.msg.requiredcompanyNameFurigana'))
        .max(256, t('purchase.msg.requiredcompanyNameFurigana'))
      : yup.string().trim(),
    applicationDepartment: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredApplicationDepartment'))
      .max(256, t('purchase.msg.requiredApplicationDepartment')),
    businessOwnerName: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredBusinessOwnerName'))
      .max(256, t('purchase.msg.requiredBusinessOwnerName')),
    businessOwnerNameFurigana: currentLanguage === 'ja' ?
      yup.string()
        .trim()
        .matches(/^[\u30A0-\u30FF\s]+$/, t('purchase.msg.matchedKatakana'))
        .required(t('purchase.msg.requiredBusinessOwnerNameFurigana'))
        .max(256, t('purchase.msg.requiredBusinessOwnerNameFurigana'))
      : yup.string().trim(),
    zipCode: currentLanguage === 'ja' ?
      yup.string()
        .trim()
        .required(t('purchase.msg.requiredZipCode'))
        .length(7, t('purchase.msg.requiredZipCode'))
        .matches(/^[0-9]+$/, t('purchase.msg.requiredZipCode'))
      : yup.string()
        .trim()
        .required(t('purchase.msg.requiredZipCode'))
        .matches(/^[0-9]+$/, t('purchase.msg.requiredZipCode')),
    address: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredAddress'))
      .max(256, t('purchase.msg.requiredAddress')),
    telephoneNumber: yup
      .string()
      .trim()
      .required(t('purchase.msg.requiredTelephoneNumber'))
      .matches(/^[0-9]+$/, t('purchase.msg.requiredTelephoneNumberHalfWidth'))
      .max(11, t('purchase.msg.requiredTelephoneNumber'))
      .min(10, t('purchase.msg.requiredTelephoneNumber')),
    amountOfLicense: yup
      .number()
      .typeError(t('purchase.msg.requiredAmountOfLicense'))
      .required(t('purchase.msg.requiredAmountOfLicense'))
      .min(1, t('purchase.msg.requiredAmountOfLicenseMin'))
      .max(10, t('purchase.msg.requiredAmountOfLicenseMaxTrial')),
    startDate: yup
      .date()
      .required(t('licenseCreate.msg.requiredStartDate'))
      .min(currentDateCustomHour, t('purchase.msg.invalidStartDate'))
      .max('2100/12/31', t('purchase.msg.invalidStartDate')),
  });

  // form stuffs
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm<RegisterInformation>({
    resolver: yupResolver(schemaInputTrial),
    defaultValues: {
      countryId: formData.countryId !== '' ? formData.countryId : 'JPN',
      publicationCompanyName: true,
    },
  });

  // end session after 1 hour no operations are performed on the site
  const [timeoutRef, setTimeoutRef] = useState<NodeJS.Timeout | undefined>();
  const timeout = 3600000; // 1 hour

  const resetTimeout = () => {
    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
    const newTimeout = setTimeout(() => {
      navigate(routes.purchase);
    }, timeout);
    setTimeoutRef(newTimeout);
  };

  // save data input when change form
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      [name]: value,
    }));
    resetTimeout();
  };

  // save selected country when change form
  const watchCountry = watch('countryId');
  useEffect(() => {
    setFormData((prevFormData: any) => ({
      ...prevFormData,
      countryId: watchCountry,
    }));
    resetTimeout();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setFormData, watchCountry]);

  // =============== Recalculate expiration date① and expiration date 1 when expiration date 1 has changed ======================
  const watchedStartDate = watch('startDate');
  useEffect(() => {
    const defaultDate = new Date().toISOString().split('T')[0];
    if (watchedStartDate < defaultDate) {
      const [selectedDateTime, selectedDateTime1] = performCalculation(defaultDate);
      setSelectedEndDate(selectedDateTime);
      setSelectedEndDate1(selectedDateTime1);
    } else {
      const [selectedDateTime, selectedDateTime1] = performCalculation(watchedStartDate);
      setSelectedEndDate(selectedDateTime);
      setSelectedEndDate1(selectedDateTime1);
    }
  }, [watchedStartDate]);
  // =============== END Recalculate expiration date① and expiration date 1 when expiration date 1 has changed ======================

  const onSubmit: SubmitHandler<RegisterInformation> = async (data) => {
    try {
      const startDateObj = new Date(data.startDate);
      startDateObj.setHours(23);
      startDateObj.setMinutes(59);

      const dataInput = {
        ...data,
        mailRegister,
        licensePurchaser,
        totalAmount,
        purchaseType,
        startDate: startDateObj,
      };
      localStorage.setItem('dataInput', JSON.stringify(dataInput));
      localStorage.setItem('purchaseType', purchaseType);
      if (param.id) {
        localStorage.setItem('oneTimeKeyId', param.id);
        window.location.href = '/purchase/ConfirmInfo';
      }
    } catch (error: any) {
      let err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[{}]/g, '').trim();
      if (err.split('=').length > 1) {
        err = err.split('=')[1].trim();
      }
      if (err === 'purchaseEmail.emailRegistered') {
        setCodeError(t('purchaseEmail.emailRegistered'));
      } else {
        setCodeError(t('purchaseEmail.systemError'));
      }
      window.scrollTo(0, 0);
    }
  };

  // event change language, turn off errors message
  const [showErrors, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  const handleFormSubmit = () => {
    setShowErrors(true);
    handleSubmit(onSubmit)();
  };

  let purchaseDetailsDescription3 = t('purchaseDetailsDescription3');
  purchaseDetailsDescription3 = purchaseDetailsDescription3.replace('{number_device}', dataCompany.availableDeviceNumber);

  return (
    <>
      <TGGrid item sm={12}>
        {showErrors && codeError && (
          <Alert severity="error" icon={false} sx={{ marginTop: '1rem' }}>
            {codeError}
          </Alert>
        )}
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('purchaseCorporateInformation')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseCountryName')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <CountrySelector
            countries={countries}
            countryId={watch('countryId')}
            handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
            error={showErrors ? 'countryId' in errors : false}
            message={showErrors ? errors.countryId?.message : ''}
            notLabel
          />
        </TGGrid>
        <TGGrid item md={5} xs={1}>
          {}
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseCompanyName')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            label=""
            registration={register('companyName')}
            error={showErrors ? 'companyName' in errors : false}
            helperText={showErrors ? errors.companyName?.message : ''}
            variant="outlined"
            value={formData.companyName}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      {currentLanguage === 'ja' && (
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseCompanyNameFurigana')}
              <span className="required-asterisk">*</span>
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <TGTextField
              fullWidth
              registration={register('companyNameFurigana')}
              error={showErrors ? 'companyNameFurigana' in errors : false}
              helperText={showErrors ? errors.companyNameFurigana?.message : ''}
              label=""
              variant="outlined"
              value={formData.companyNameFurigana}
              onInput={handleInputChange}
            />
          </TGGrid>
        </TGGrid>
      )}
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseApplicationDepartment')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('applicationDepartment')}
            error={showErrors ? 'applicationDepartment' in errors : false}
            helperText={showErrors ? errors.applicationDepartment?.message : ''}
            label=""
            variant="outlined"
            value={formData.applicationDepartment}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('purchaseCorporateManagerInformation')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid my={1}>
        <List>
          <ListItem sx={{ padding: '0' }}>
            <ListItemText primary={t('purchaseCorporateManagerInformationDescription1')} />
          </ListItem>
          <ListItem sx={{ padding: '0' }}>
            <ListItemText primary={t('purchaseCorporateManagerInformationDescription2')} />
          </ListItem>
        </List>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseBusinessOwnerName')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('businessOwnerName')}
            error={showErrors ? 'businessOwnerName' in errors : false}
            helperText={showErrors ? errors.businessOwnerName?.message : ''}
            label=""
            variant="outlined"
            value={formData.businessOwnerName}
            onInput={handleInputChange}
          />
        </TGGrid>
        <TGGrid item md={5} xs={1}>
          {}
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="h6">{t('purchaseBusinessOwnerNameDescription')}</Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>
          {}
        </TGGrid>
      </TGGrid>
      {currentLanguage === 'ja' && (
        <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('purchaseBusinessOwnerNameFurigana')}
              <span className="required-asterisk">*</span>
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <TGTextField
              fullWidth
              registration={register('businessOwnerNameFurigana')}
              error={showErrors ? 'businessOwnerNameFurigana' in errors : false}
              helperText={showErrors ? errors.businessOwnerNameFurigana?.message : ''}
              label=""
              variant="outlined"
              value={formData.businessOwnerNameFurigana}
              onInput={handleInputChange}
            />
          </TGGrid>
          <TGGrid item md={5} xs={1}>
            {}
          </TGGrid>
        </TGGrid>
      )}
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseEmailAddress')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" component="a" href={`mailto:${mailRegister}`} className="fs-09-rem">
            {mailRegister}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={1}>
          {}
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseZipcode')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid mr={1}>〒</TGGrid>
        <TGGrid container item xs={9}>
          <TGGrid item md={2} xs={5}>
            <TGTextField
              fullWidth
              registration={register('zipCode')}
              error={showErrors ? 'zipCode' in errors : false}
              label=""
              variant="outlined"
              defaultValue={formData.zipCode}
              onInput={handleInputChange}
            />
          </TGGrid>
          <TGGrid item md={5} xs={5} mt={1} ml={1}>
            <Typography variant="h6">
              {t('purchaseZipCodeDes')}
            </Typography>
          </TGGrid>
          {errors.zipCode && (
            <TGGrid item xs={12} className="text-error">
              {showErrors ? errors.zipCode.message : ''}
            </TGGrid>
          )}
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" my={1} mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseAddress')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            registration={register('address')}
            error={showErrors ? 'address' in errors : false}
            helperText={showErrors ? errors.address?.message : ''}
            label=""
            variant="outlined"
            value={formData.address}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseTelephoneNumber')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            inputProps={{
              maxLength: 11,
            }}
            registration={register('telephoneNumber')}
            error={showErrors ? 'telephoneNumber' in errors : false}
            helperText={showErrors ? errors.telephoneNumber?.message : ''}
            label=""
            variant="outlined"
            value={formData.telephoneNumber}
            onInput={handleInputChange}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8} mt={1}>
          <Typography variant="h6">{t('purchaseTelephoneDescription')}</Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('TrailDetails')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('TrailAmountOfLicense')}
            <span className="required-asterisk">*</span>
          </Typography>
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <TGGrid item md={1} xs={8}>
            <TGTextField
              fullWidth
              type="number"
              onKeyDown={(e) => {
                if (e.key === ',' || e.key === '.' || e.key === '-') {
                  e.preventDefault();
                }
              }}
              registration={register('amountOfLicense')}
              error={showErrors ? 'amountOfLicense' in errors : false}
              inputProps={{
                min: 1,
                max: 100,
              }}
              label=""
              variant="outlined"
              defaultValue={formData.amountOfLicense}
              onInput={handleInputChange}
            />
          </TGGrid>
          {errors.amountOfLicense && (
            <TGGrid item xs={12} className="text-error">
              {showErrors ? errors.amountOfLicense.message : ''}
            </TGGrid>
          )}
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('purchaseDetailsDescription2')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDetailsDescription3')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={purchaseDetailsDescription3} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDetailsDescription5')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDetailsDescription6')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDetailsDescription7')} />
            </ListItem>
          </List>
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem">
            {t('purchaseDateStartLicense')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <TGTextField
            fullWidth
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            defaultValue={formData.startDate === '' ? formatCurrentDate : formData.startDate}
            registration={register('startDate')}
            error={showErrors ? 'startDate' in errors : false}
            helperText={showErrors ? errors.startDate?.message : ''}
            label=""
            variant="outlined"
            onInput={handleInputChange}
          />
        </TGGrid>
        <TGGrid item md={5} xs={1}>
          {}
        </TGGrid>
        <TGGrid item md={2} xs={3}>
          {}
        </TGGrid>
        <TGGrid item md={10} xs={9}>
          <List sx={{ padding: '0' }}>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription1')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription2')} />
            </ListItem>
            <ListItem sx={{ padding: '0' }}>
              <ListItemText primary={t('TrailDateStartLicenseDescription3')} />
            </ListItem>
          </List>
        </TGGrid>
        <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('TrailDateEndLicense1')}
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">
              {selectedEndDate1}
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>
            {}
          </TGGrid>
          <TGGrid item md={2} xs={3}>
            {}
          </TGGrid>
          <TGGrid item md={10} xs={9}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('TrailDateEndLicenseDescription1')} />
              </ListItem>
            </List>
          </TGGrid>
          <TGGrid item md={2} xs={3}>
            <Typography variant="body1" className="fs-09-rem">
              {t('TrailDateEndLicense2')}
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={8}>
            <Typography variant="body1" className="fs-09-rem">
              {selectedEndDate2}
            </Typography>
          </TGGrid>
          <TGGrid item md={5} xs={1}>
            {}
          </TGGrid>
          <TGGrid item md={2} xs={3}>
            {}
          </TGGrid>
          <TGGrid item md={10} xs={9}>
            <List>
              <ListItem sx={{ padding: '0' }}>
                <ListItemText primary={t('TrailDateEndLicenseDescription2')} />
              </ListItem>
            </List>
          </TGGrid>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1}>
        {t('RegardingPublicationCorporateName')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" spacing={1} mt={1}>
        <TGGrid item md={10} xs={10}>
          <Typography variant="body1" className="fs-09-rem">
            {t('RegardingPublicationCorporateNameEx')}
          </Typography>
        </TGGrid>
        <TGGrid item xs={2} sm={2}>
          <IsPublicationCorporateNameRadio
            handleChange={(e, value) => setValue('publicationCompanyName', value === 'true')}
            value={watch('publicationCompanyName')}
          />
        </TGGrid>
      </TGGrid>
      <TGGrid container mt={5} mb={3} display="flex" justifyContent="right">
        <TGGrid item md={8} sm={11}>
          <Box display="flex" alignItems="center" justifyContent="right">
            <Button
              variant="contained"
              sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
              className="purchase-button-gray"
              onClick={handleOpenDialog}
            >
              {t('purchaseEmail.button.cancel')}
            </Button>
            <Button
              variant="contained"
              sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
              onClick={handleFormSubmit}
            >
              {t('Trail.button.register')}
            </Button>
          </Box>
        </TGGrid>
      </TGGrid>
      <DialogConfirmCancel open={open} onClose={handleCloseDialog} onConfirm={handleConfirmation} />
    </>
  );
}
