/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, FormControl, TextField, MenuItem, Checkbox, Paper, Button, Container } from '@mui/material';
import { useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import routes from '../../constants/routes';
import TGGrid from '../../component/Elements/TGGrid';
import Logo from '../layout/main/header/logo';
import MainCard from '../../component/maincard';
import { getTrialSetting } from '../../state/actions/setting';
import { start } from '../../lib/saga-promise';
import { getAllPriceLicense } from '../../state/actions/price';
import { PRICE_LICENSE_ID_COMPANY, PRICE_LICENSE_ID_USER } from '../../constants/prices';


export default function Purchase() {
  // hooks
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  // states

  const isTrialOn: boolean = useSelector((state: RootStateOrAny) => state.setting.isTrialOn);
  useEffect(() => {
    localStorage.removeItem('oneTimeKeyId');
    localStorage.removeItem('dataInput');
    localStorage.removeItem('infoForm');
    localStorage.removeItem('purchaseType');
    start(getTrialSetting, { noLoading: true }, dispatch);
  }, [dispatch]);

  const [isChecked, setIsChecked] = useState(false);
  let locate = sessionStorage.getItem('locale');

  if (locate !== 'ja' && locate !== 'en') {
    locate = 'ja';
    sessionStorage.setItem('locale', locate);
    localStorage.setItem('locale', locate);
    i18n.changeLanguage(locate);
  }
  const [valueLocate, setValueLocate] = useState<string | null>(locate);
  const changeLocate = (event: ChangeEvent<HTMLInputElement>) => {
    setValueLocate(event.target.value);
    sessionStorage.setItem('locale', event.target.value);
    i18n.changeLanguage(event.target.value);
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleTextClick = () => {
    setIsChecked(!isChecked);
  };

  const locates = [
    {
      name: t('login.lable.japanese'),
      value: 'ja',
    },
    {
      name: t('login.lable.english'),
      value: 'en',
    },
  ];
  useEffect(() => {
    const meta = document.createElement('meta');
    meta.name = 'robots';
    meta.content = 'noindex';
    document.getElementsByTagName('head')[0].appendChild(meta);
  }, []);

  const [dataUser, setdataUser] = useState({
    price: '',
    priceLicenseMonth: '',
    releaseDate: '',
  });

  const [dataCompany, setdataCompany] = useState({
    price: '',
    priceLicenseMonth: '',
    releaseDate: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res : any = await start(getAllPriceLicense, null, dispatch);
        if (Array.isArray(res) && res.length > 0) {
          setdataUser(res.find((item) => item.priceLicenseId === PRICE_LICENSE_ID_USER));
          setdataCompany(res.find((item) => item.priceLicenseId === PRICE_LICENSE_ID_COMPANY));
        }
      } catch (error) {
        // eslint-disable-next-line no-empty
      }
    };
    fetchData();
  }, [dispatch]);

  const priceLicenseMonthCompanyArray = dataCompany.priceLicenseMonth
    .split(',')
    .map((item) => parseInt(item, 10));

  let text = t('purchase.label.termsOfUse1');
  const replacements = [
    '{price_month_1}', '{price_month_2}', '{price_month_3}', '{price_month_4}', '{price_month_5}', '{price_month_6}', '{price_month_7}', '{price_month_8}',
    '{price_month_9}', '{price_month_10}', '{price_month_11}', '{price_month_12}'
  ];

  const releaseDate = new Date(dataUser.releaseDate);
  const formattedDate = `${releaseDate.getFullYear()}年${String(releaseDate.getMonth() + 1).padStart(2, '0')}月${String(releaseDate.getDate()).padStart(2, '0')}日制定`;

  const options : Intl.DateTimeFormatOptions = { year: 'numeric', month: 'long', day: '2-digit' };
  const formattedDateEn = releaseDate.toLocaleDateString('en-US', options);
  const output = `Established on ${formattedDateEn}`;

  text = text.replace('{price_user}', dataUser.price.toLocaleString());
  if (locate === 'ja') {
    text = text.replace('{date_of_issue}', formattedDate);
  } else if (locate === 'en') {
    text = text.replace('{date_of_issue}', output);
  }

  replacements.forEach((oldValue, index) => {
    if (priceLicenseMonthCompanyArray[index] !== undefined) {
      const newValue = priceLicenseMonthCompanyArray[index].toLocaleString();
      const regex = new RegExp(oldValue, 'g');
      text = text.replace(regex, newValue);
    }
  });

  return (
    <div className="login__container">
      <Container disableGutters maxWidth="xl" component="main">
        <MainCard sx={{ px: 5, pt: 3 }}>
          <TGGrid container columns={12}>
            <TGGrid item sm={4} display="flex" alignItems="center">
              <Logo />
            </TGGrid>
            <TGGrid item sm={6} display="flex" justifyContent="flex-end" alignItems="center" paddingRight="15px">
              <Typography>{t('login.lable.locate')}</Typography>
            </TGGrid>
            <TGGrid item sm={2}>
              <FormControl fullWidth sx={{ mb: 1 }}>
                <TextField
                  select
                  fullWidth
                  onChange={changeLocate}
                  value={valueLocate}
                >
                  {locates.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <MenuItem key={index} value={item.value}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            </TGGrid>
          </TGGrid>
          <Typography mt={4}>{t('purchase.label.checkTerms')}</Typography>
          <TGGrid item xs={12} sm={12} mt={3}>
            <TGGrid item sm={12}>
              <Paper
                elevation={1}
                sx={{
                  width: '100%',
                  height: 460,
                  overflow: 'auto',
                  border: '1px solid #C0C0C0',
                  backgroundColor: 'theme.palette.primary.main',
                }}
              >
                <Typography
                  padding={1}
                  sx={{
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    fontSize: '13px',
                  }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              </Paper>
            </TGGrid>
          </TGGrid>
          {!isTrialOn && (
            <TGGrid container columns={12}>
              <TGGrid item sm={8} pl={5} mt={2}>
                <Typography mt={4} display="flex" alignItems="center" onClick={handleTextClick}>
                  <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                  {t('purchase.label.acceptTerms')}
                </Typography>
              </TGGrid>
              <TGGrid item sm={4} mt={6} pl={5}>
                <Button
                  variant="contained"
                  sx={{
                    width: 200,
                    color: '#ffffff !important',
                    fontSize: '14px',
                  }}
                  disabled={!isChecked}
                  component={Link}
                  to={`${routes.inputEmail}?purchase=Buy`}
                >
                  {t('purchase.label.buttonBuyLicense')}
                </Button>
              </TGGrid>
            </TGGrid>
          )}
          {isTrialOn && (
            <TGGrid container columns={12}>
              <TGGrid item sm={8} pl={5} mt={2}>
                <Typography mt={4} display="flex" alignItems="center" onClick={handleTextClick}>
                  <Checkbox checked={isChecked} onChange={handleCheckboxChange} />
                  {t('purchase.label.acceptTerms')}
                </Typography>
              </TGGrid>
              <TGGrid item sm={4} mt={6} pl={5}>
                <Button
                  variant="contained"
                  sx={{
                    width: 250,
                    color: '#ffffff !important',
                    fontSize: '14px',
                  }}
                  disabled={!isChecked}
                  component={Link}
                  to={`${routes.inputEmail}?purchase=Try`}
                  state={{ purchase: 'Try' }}
                >
                  {t('purchase.label.buttonTryItForFree')}
                </Button>
              </TGGrid>
            </TGGrid>
          )}
        </MainCard>
      </Container>
    </div>
  );
}
