import React, { useEffect, Suspense } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import routerPage from '../../router';
import { start } from '../../../lib/saga-promise';
import { getProfile } from '../../../state/actions/auth';
import Loading from '../../../component/loading';
import routeContants from '../../../constants/routes';
import WrapMain from './wrapMain';
import authStatusConst from '../../../constants/authStatus';

function MainLayout() {
  const history = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const routes = Object.values(routerPage);

  const loading = useSelector((state: RootStateOrAny) => state.app.loading);
  const authStatus = useSelector((state: RootStateOrAny) => state.auth.authStatus);
  const loggedIn = useSelector((state: RootStateOrAny) => state.auth.loggedIn);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  const getProfileUser = async () => {
    try {
      await start(getProfile, null, dispatch);
      history(routeContants.dashboard);
    } catch (error: any) {
      history(routeContants.login);
    }
  };

  useEffect(() => {
    if (loggedIn) {
      if (authStatus === authStatusConst.require_change_password) {
        history(routeContants.passwordExpiredChange);
      }
      if (!pathname.startsWith('/password/reset') && !pathname.startsWith('/link') && !pathname.startsWith('/remind') && !pathname.startsWith('/purchase')) {
        getProfileUser();
      }
      if ((pathname.startsWith('/purchase/buyLicense')) || (pathname.startsWith('/purchase/buyAdditionalLicenses')) || (pathname.startsWith('/purchase/updateStop')) || (pathname.startsWith('/purchase/reopenLicenses')) || (pathname.startsWith('/purchase/updateBankTransfer')) || (pathname.startsWith('/purchase/changePaymentMethod')) || (pathname.startsWith('/purchase/changePaymentMethodSuccess'))) {
        const parts = pathname.split('/');
        const userId = parts[parts.length - 1];
        if (userId !== user.userId) {
          history(routeContants.pageNotFound);
        }
      }
    } else if ((!pathname.startsWith('/password/reset') && !pathname.startsWith('/link') && !pathname.startsWith('/remind') && !pathname.startsWith('/purchase') && !pathname.startsWith('/404') && !pathname.startsWith('/expiredPage')) || (pathname.startsWith('/purchase/buyLicense')) || (pathname.startsWith('/purchase/buyAdditionalLicenses')) || (pathname.startsWith('/purchase/updateStop')) || (pathname.startsWith('/purchase/reopenLicenses')) || (pathname.startsWith('/purchase/updateBankTransfer')) || (pathname.startsWith('/purchase/changePaymentMethod')) || (pathname.startsWith('/purchase/changePaymentMethodSuccess'))) {
      history(routeContants.login, { state: { prevLocation: pathname } });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedIn, pathname]);

  const renderPage = ({ main, noLayout }: any) => {
    main = React.createElement(main);

    // wrap for lazy load
    const component = (
      <Suspense fallback={<Loading loading type="block" />}>
        {main}
      </Suspense>
    );

    if (noLayout) {
      return (
        <main>
          <Loading loading={loading} type="full" />
          {component}
        </main>
      );
    }
    if (!user) return null;
    return (
      <WrapMain>
        {component}
      </WrapMain>
    );
  };

  return (
    <Routes>
      {
        routes.map(({ path, ...layoutProps }) => {
          const element = renderPage(layoutProps);
          return (
            <Route
              key={path}
              path={path}
              element={element}
            />
          );
        })
      }
    </Routes>
  );
}

export default MainLayout;
