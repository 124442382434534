import { types } from '../actions/price';

interface PriceLicenseRequest {
  type: string;
  payload?: any;
}

type PriceLicenseActions = PriceLicenseRequest;

interface IPriceLicenseState {
  priceLicense: any;
  count: number;
}

const initialState: IPriceLicenseState = {
  priceLicense: [],
  count: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: PriceLicenseActions) => {
  switch (type) {
    case types.GET_ALL_PRICE_LICENSE_SUCCESS:
      state.priceLicense = payload.list;
      state.count = payload.count;
      return {
        ...state,
      };
    default:
      return state;
  }
};
