/* eslint-disable react/no-unstable-nested-components */
import { Typography, Divider } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import moment from 'moment';
import { start } from '../../../lib/saga-promise';
import TGGrid from '../../../component/Elements/TGGrid';
import { getCountryById } from '../../../state/actions/country';
import { licenseDetailInPurchase } from '../../../state/actions/license';
import { MANAGE_LICENSE, THUMGY_DOWNLOAD_LINK } from '../../../constants/app';

type Props = {
  buyerDetail: any;
};

const manageLicense = MANAGE_LICENSE;
const thumgyDownloadLink = THUMGY_DOWNLOAD_LINK;

export default function InformationUser({ buyerDetail }: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [countryName, SetCountryName] = useState('');
  const dataCountry = useSelector((state: RootStateOrAny) => state.country.country);
  useEffect(() => {
    start(getCountryById, { countryId: buyerDetail.countryId }, dispatch);
  }, [dispatch, buyerDetail.countryId]);

  useEffect(() => {
    if (dataCountry.countryNameJp) {
      SetCountryName(dataCountry.countryNameJp);
    }
  }, [dataCountry]);

  // get list data license
  const [licenseCode, SetLicenseCode] = useState('');
  const [deviceNumber, SetDeviceNumber] = useState('');
  const [expireDate, SetExpireDate] = useState('');
  const dataLicense = useSelector((state: RootStateOrAny) => state.license.license);
  useEffect(() => {
    start(licenseDetailInPurchase, { licenseId: buyerDetail.licenseId }, dispatch);
  }, [dispatch, buyerDetail.licenseId]);

  // set data license to the table
  useEffect(() => {
    if (dataLicense.licenseCode) {
      SetLicenseCode(dataLicense.licenseCode);
      SetDeviceNumber(dataLicense.availableDeviceNumber);
      if (dataLicense.purchaseType === 'Trial') {
        SetExpireDate(moment(dataLicense.endDateTrail).format('YYYY/MM/DD'));
      } else {
        SetExpireDate(moment(dataLicense.endDate).format('YYYY/MM/DD'));
      }
    }
  }, [dataLicense]);

  return (
    <>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.startDate')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.startDate')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {moment(buyerDetail.startTimeLicense).format('YYYY/MM/DD')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.userInfo')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.userId')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {buyerDetail.loginId}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.fullName')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {buyerDetail.userName}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.emailAddress')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography component="a" href={`mailto:${buyerDetail.mailRegister}`} variant="body1" className="fs-09-rem font-NotoSansJP">
            {buyerDetail.mailRegister}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.countryName')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {countryName}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.nickname')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {buyerDetail.nickName}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.nicknameDecription')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2} mb={1}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.temporaryPassword')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {buyerDetail.password}
          </Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.licenseInfo')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.licenseNumber')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {licenseCode}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.numberOfAvailableTerminals')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {deviceNumber}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.dateOfExpiry')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {expireDate}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.licenseManage')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" component="a" href={manageLicense} className="fs-09-rem font-NotoSansJP">
            {manageLicense}
          </Typography>
        </TGGrid>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={1} mb={3}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.licenseDecription')}
          </Typography>
        </TGGrid>
      </TGGrid>
      <Typography variant="h4" mt={3} mb={1} className="font-NotoSansJP">
        {t('purchaseBuyerInformation.success.label.titleInstall')}
      </Typography>
      <Divider sx={{ borderBottom: '2px solid' }} />
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={3}>
        <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
          {t('purchaseBuyerInformation.success.label.contentInstall')}
        </Typography>
      </TGGrid>
      <TGGrid container direction="row" justifyContent="start" alignItems="center" mt={2}>
        <TGGrid item md={2} xs={3}>
          <Typography variant="body1" className="fs-09-rem font-NotoSansJP">
            {t('purchaseBuyerInformation.success.label.textLinkInstall')}
          </Typography>
        </TGGrid>
        <TGGrid item md={5} xs={8}>
          <Typography variant="body1" component="a" href={thumgyDownloadLink} className="fs-09-rem font-NotoSansJP">
            {thumgyDownloadLink}
          </Typography>
        </TGGrid>
      </TGGrid>
    </>
  );
}
