import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconKey,
  IconHome,
  IconUserCircle,
  IconBuilding,
  IconLicense,
  IconListSearch,
  IconUserPlus,
  IconFolderPlus,
  IconUsers,
  IconListCheck,
  IconFilePlus,
  IconDeviceAnalytics,
  IconNotes,
  IconDatabase,
  IconNotebook,
  IconBoxMultiple9,
  IconReceipt2,
} from '@tabler/icons';

import { IconBuildingCircus, IconTicket } from '@tabler/icons-react';

import roles from '../../../../constants/roles';
import routes from '../../../../constants/routes';
import Icon10Day from './icon10day';
import Icon60Day from './icon60day';

const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconKey,
  IconHome,
  IconUserCircle,
  IconBuilding,
  IconLicense,
  IconListSearch,
  IconUserPlus,
  IconFolderPlus,
  IconUsers,
  IconListCheck,
  IconFilePlus,
  IconDeviceAnalytics,
  IconNotes,
  IconDatabase,
  IconNotebook,
  IconBoxMultiple9,
  IconBuildingCircus,
  IconTicket,
  Icon10Day,
  Icon60Day,
  IconReceipt2,
};

const menus = [
  {
    id: 'dashboard',
    title: '',
    type: 'group',
    roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
    children: [
      {
        id: 'dashboard',
        title: 'dashboard.label.top',
        type: 'item',
        url: routes.dashboard,
        icon: icons.IconHome,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'utilities',
    title: '',
    type: 'group',
    roles: [roles.systemAdmin],
    children: [
      {
        id: 'user',
        title: 'menu.btn.userManagement',
        type: 'collapse',
        icon: icons.IconUserCircle,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchUser',
            title: 'menu.btn.userMaster',
            type: 'item',
            url: routes.userSearch,
            icon: icons.IconUsers,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
          {
            id: 'createUser',
            title: 'menu.btn.userRegist',
            type: 'item',
            url: routes.userCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'group',
        title: 'menu.btn.groupManagement',
        type: 'collapse',
        icon: icons.IconBuilding,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchGroup',
            title: 'menu.btn.groupSearch',
            type: 'item',
            url: routes.groupSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createGroup',
            title: 'menu.btn.groupRegist',
            type: 'item',
            url: routes.groupCreate,
            icon: icons.IconFolderPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'license',
        title: 'menu.btn.licenseManagement',
        type: 'collapse',
        icon: icons.IconLicense,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        children: [
          {
            id: 'searchLicense',
            title: 'menu.btn.licenseMaster',
            type: 'item',
            url: routes.licenseSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createLicense',
            title: 'menu.btn.licenseRegist',
            type: 'item',
            url: routes.licenseCreate,
            icon: icons.IconFilePlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'userLicense',
            title: 'menu.btn.userLicenseSearch',
            type: 'item',
            url: routes.licenseUser,
            icon: icons.IconListCheck,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'deviceLicense',
            title: 'menu.btn.deviceSetting',
            type: 'item',
            url: routes.licenseDevice,
            icon: icons.IconDeviceAnalytics,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
          {
            id: 'mailLicense10Day',
            title: 'menu.btn.mailLicense10Day',
            type: 'item',
            url: routes.licenseMailEndDateTrial10Day,
            icon: icons.Icon10Day,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'mailLicense60Day',
            title: 'menu.btn.mailLicense60Day',
            type: 'item',
            url: routes.licenseMailEndDate60Day,
            icon: icons.Icon60Day,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'mailLicensePayment',
            title: 'menu.btn.mailLicensePayment',
            type: 'item',
            url: routes.licenseMailPayment,
            icon: icons.IconNotes,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'stripeTransactions',
            title: 'menu.btn.stripeTransactions',
            type: 'item',
            url: routes.stripeTransactions,
            icon: icons.IconReceipt2,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'version',
        title: 'menu.btn.versionControl',
        type: 'collapse',
        icon: icons.IconNotebook,
        roles: [roles.systemAdmin],
        children: [
          {
            id: 'versionRegistration',
            title: 'menu.btn.versionRegistration',
            type: 'item',
            url: routes.versionCreate,
            icon: icons.IconNotes,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'versionHistory',
            title: 'menu.btn.versionHistory',
            type: 'item',
            url: routes.versionHistory,
            icon: icons.IconDatabase,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'UserInfoReadMessage',
            title: 'userInfoReadMessage.label.top',
            type: 'item',
            url: routes.userinfoReadMessage,
            icon: icons.IconBoxMultiple9,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'eventSetting',
        title: 'menu.btn.eventSetting',
        type: 'collapse',
        icon: icons.IconBuildingCircus,
        roles: [roles.systemAdmin],
        children: [
          {
            id: 'eventTry',
            title: 'menu.btn.eventTry',
            type: 'item',
            url: routes.eventSettingTrial,
            icon: icons.IconTicket,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
  {
    id: 'utilities',
    title: '',
    type: 'group',
    roles: [roles.groupAdmin],
    children: [
      {
        id: 'user',
        title: 'menu.btn.userManagement',
        type: 'collapse',
        icon: icons.IconUserCircle,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchMUser',
            title: 'menu.btn.adminUserSearch',
            type: 'item',
            url: routes.muserSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createMUser',
            title: 'menu.btn.adminUserRegist',
            type: 'item',
            url: routes.muserCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'searchUser',
            title: 'menu.btn.userMaster',
            type: 'item',
            url: routes.userSearch,
            icon: icons.IconUsers,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
          {
            id: 'createUser',
            title: 'menu.btn.userRegist',
            type: 'item',
            url: routes.userCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'group',
        title: 'menu.btn.groupManagement',
        type: 'collapse',
        icon: icons.IconBuilding,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchGroup',
            title: 'menu.btn.groupSearch',
            type: 'item',
            url: routes.groupSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createGroup',
            title: 'menu.btn.groupRegist',
            type: 'item',
            url: routes.groupCreate,
            icon: icons.IconFolderPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'license',
        title: 'menu.btn.licenseManagement',
        type: 'collapse',
        icon: icons.IconLicense,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        children: [
          {
            id: 'searchLicense',
            title: 'menu.btn.licenseMaster',
            type: 'item',
            url: routes.licenseSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createLicense',
            title: 'menu.btn.licenseRegist',
            type: 'item',
            url: routes.licenseCreate,
            icon: icons.IconFilePlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'userLicense',
            title: 'menu.btn.userLicenseSearch',
            type: 'item',
            url: routes.licenseUser,
            icon: icons.IconListCheck,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'deviceLicense',
            title: 'menu.btn.deviceSetting',
            type: 'item',
            url: routes.licenseDevice,
            icon: icons.IconDeviceAnalytics,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
  {
    id: 'utilities',
    title: '',
    type: 'group',
    roles: [roles.user],
    children: [
      {
        id: 'user',
        title: 'menu.btn.userManagement',
        type: 'collapse',
        icon: icons.IconUserCircle,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchMUser',
            title: 'menu.btn.adminUserSearch',
            type: 'item',
            url: routes.muserSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createMUser',
            title: 'menu.btn.adminUserRegist',
            type: 'item',
            url: routes.muserCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'searchUser',
            title: 'menu.btn.userMaster',
            type: 'item',
            url: routes.userSearch,
            icon: icons.IconUsers,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
          {
            id: 'createUser',
            title: 'menu.btn.userRegist',
            type: 'item',
            url: routes.userCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'license',
        title: 'menu.btn.licenseManagement',
        type: 'collapse',
        icon: icons.IconLicense,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        children: [
          {
            id: 'searchLicense',
            title: 'menu.btn.licenseMaster',
            type: 'item',
            url: routes.licenseSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createLicense',
            title: 'menu.btn.licenseRegist',
            type: 'item',
            url: routes.licenseCreate,
            icon: icons.IconFilePlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'userLicense',
            title: 'menu.btn.userLicenseSearch',
            type: 'item',
            url: routes.licenseUser,
            icon: icons.IconListCheck,
            roles: [roles.systemAdmin, roles.groupAdmin],
            showForPersonal: true,
            breadcrumbs: false,
          },
          {
            id: 'deviceLicense',
            title: 'menu.btn.deviceSetting',
            type: 'item',
            url: routes.licenseDevice,
            icon: icons.IconDeviceAnalytics,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default menus;
