/* eslint-disable import/no-cycle */
import axios from 'axios';
import queryString from 'query-string';
import { BASE_URL } from '../constants/app';
import { logout } from '../state/actions/auth';
import store from '../state/store';
import routeContants from '../constants/routes';

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => queryString.stringify(params, {
    encode: true,
    arrayFormat: 'bracket',
  }),
});

axiosClient.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

axiosClient.interceptors.response.use(
  (response) => {
    if (response && response.data) {
      return response.data;
    }
    return response;
  },
  (error) => {
    // Do something with response error
    if (error.response?.status === 401) {
      store.dispatch(logout());
    } else if (error.response?.status === 404) {
      // redirect to 404 page
      window.location.href = routeContants.pageNotFound;
    } else if (error.response?.status === 400) {
      // redirect to purchase page
      if (error.response?.data?.indexOf('5170') !== -1) {
        window.location.href = routeContants.expiredPage;
      }
    }
    return Promise.reject(error.response);
  }
);
