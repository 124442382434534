/* eslint-disable react/no-array-index-key */
/* eslint-disable max-len */
import {
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { TableColumn } from '../../types';

type Props<TColumn> = {
  totalCount: number;
  columns: TColumn[];
  rows: any[];
  tableCell: (row: any, rowKey: number, column: TColumn) => React.ReactNode;
};

export default function SearchResult<TColumn extends TableColumn>({ totalCount, columns, rows, tableCell }: Props<TColumn>) {
  return (
    <Paper variant="outlined" sx={{ width: '100%', overflow: 'hidden' }}>
      <Stack spacing={2} sx={{ float: 'right', padding: 1 }} direction="row" alignItems="center">
        <Typography variant="body2">{`${t('searchResult.label.all')} ${totalCount} ${t('searchResult.label.items')}`}</Typography>
      </Stack>
      <TableContainer>
        <Table stickyHeader sx={{ whiteSpace: 'nowrap' }} size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  sx={{ minWidth: column.minWidth, background: '#203462', color: 'white', pt: 1, pb: 1 }}
                  className="fs-custom"
                >
                  {t(column.label)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row: any, rowKey: number) => (
              <TableRow hover role="checkbox" tabIndex={-1} key={rowKey}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} className="fs-custom">
                    {tableCell(row, rowKey, column)}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
