import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Alert,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  ListItemText,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getToken } from '../../state/actions/auth';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import Logo from '../layout/main/header/logo';
import { passwordReset } from '../../state/actions/user';
import { getRegExpSchemaPasswordPolicy } from '../../component/helpers/utility';

interface FormInput {
  password: string;
  passwordConfirm: string;
}

export default function PasswordReset() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();
  const token = useSelector((state: RootStateOrAny) => state.auth.token);
  const history = useNavigate();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [codeError, setCodeError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [schema, setSchema] = useState(yup.object());

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    (async () => {
      try {
        if (param.id) {
          await start(getToken, { oneTimeKeyId: param.id }, dispatch);
          // eslint-disable-next-line max-len
          const reg = getRegExpSchemaPasswordPolicy(token.isRequiredLowerAlphabetChars, token.isRequiredUpperAlphabetChars, token.isRequiredNumericChars, token.isRequiredMarkChars);
          let validateMsg = token.isRequiredLowerAlphabetChars ? t('PasswordLowerAlphabetChars') : '';
          validateMsg += token.isRequiredUpperAlphabetChars ? t('PasswordUpperAlphabetChars') : '';
          validateMsg += token.isRequiredNumericChars ? t('PasswordNumericChars') : '';
          validateMsg += token.isRequiredMarkChars ? t('PasswordMarkChars') : '';
          let pas107 = t('Password107') + t('Password108') + t('Password109');
          if (validateMsg === '') {
            pas107 = t('Password107') + t('Password109');
          }
          const schemaEdit = yup.object({
            password: yup
              .string()
              .required(t('changePassword.msg.requiredNewPassword'))
              .min(token.minimumLength, pas107.replace('{0}', validateMsg).replace('{1}', token.minimumLength))
              .max(127, t('changePassword.msg.requiredNewPassword'))
              .matches(reg, pas107.replace('{0}', validateMsg).replace('{1}', token.minimumLength)),
            passwordConfirm: yup
              .string()
              .oneOf([yup.ref('password')], t('changePassword.msg.unmatchedNewPassword'))
              .required(t('changePassword.msg.requiredNewPasswordConfirm')),
          });
          setSchema(schemaEdit);
        }
      } catch (error) {
        history(routes.login);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInput> = async ({ password }) => {
    try {
      await start(passwordReset, { oneTimeKeyId: param.id, password }, dispatch);
      setCodeError('');
      setIsSuccess(true);
    } catch (error: any) {
      const { data } = error;
      // Replace '=' with ':'
      const correctedData = data.replace(/=/g, ':');
      // Ensure the string is valid JSON by surrounding keys with double quotes
      const validJsonString = correctedData.replace(/(\w+)/g, '"$1"');
      // Parse the corrected JSON string into an object
      const jsonError = JSON.parse(validJsonString);

      if (jsonError?.errorCode) {
        setCodeError(t(jsonError.errorCode));
      } else if (jsonError?.message) {
        setCodeError(t(jsonError.message));
      } else {
        setCodeError(t('remind.msg.userId.notFound'));
      }
    }
  };

  return (
    <div className="login__container">
      <Card className="login__card">
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
            <Logo />
          </Box>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography color={theme.palette.primary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
              {t('changePassword.label.passwordReset')}
            </Typography>
          </Stack>
          {codeError && (
            <Alert severity="error" icon={false}>
              {codeError}
            </Alert>
          )}
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="center" mt={2}>
              {!isSuccess && (
                <Stack>
                  <Grid container rowSpacing={1} sx={{ mb: 3 }}>
                    <Grid item xs={4}>
                      <Typography variant="h5" color="inherit">
                        {t('common.label.userid')}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h5" color="inherit">
                        {token.loginId}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="h5" color="inherit">
                        {t('common.label.email')}
                      </Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography variant="h5" color="inherit">
                        {token.email}
                      </Typography>
                    </Grid>
                  </Grid>

                  <ListItemText
                    primary={(
                      <Typography variant="h5" color="inherit">
                        {t('changePassword.label.newPassword')}
                      </Typography>
                    )}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    type={showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickShowPassword} edge="end">
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                      sx: { mt: 0 }
                    }}
                    sx={{ mt: 0 }}
                    {...register('password')}
                    error={'password' in errors}
                    helperText={errors.password?.message}
                    style={{
                      whiteSpace: 'pre-line'
                    }}
                  />
                  <ListItemText
                    primary={(
                      <Typography variant="h5" color="inherit">
                        {t('changePassword.label.NewPasswordConfirm')}
                      </Typography>
                    )}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    size="small"
                    type="password"
                    id="passwordConfirm"
                    autoComplete="current-password"
                    sx={{ mt: 0 }}
                    {...register('passwordConfirm')}
                    error={'passwordConfirm' in errors}
                    helperText={errors.passwordConfirm?.message}
                  />

                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('common.btn.regist')}
                    </Button>
                  </Box>
                </Stack>
              )}
              {isSuccess && (
                <Stack>
                  <Stack sx={{ mb: 3 }}>
                    <Typography>
                      {t('changePassword.msg.passwordRegister')}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom mt={2} sx={{ textAlign: 'left' }}>
              <Link to={routes.login}>{t('remind.link.returnLogin')}</Link>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
