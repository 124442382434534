/* eslint-disable react/no-danger */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { Typography, useMediaQuery, Button, Box, Alert } from '@mui/material';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { start } from '../../lib/saga-promise';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import TGGrid from '../../component/Elements/TGGrid';
import routes from '../../constants/routes';
import StepPurchase from './stepPurchase';
import TGTextField from '../../component/Elements/TGTextField';
import { inputEmail } from '../../state/actions/purchase';
import { MANAGE_LICENSE } from '../../constants/app';

interface FormInput {
  email: string;
  reEmail: string;
}
export default function InputEmail() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const url =
    queryParams.get('purchase') === 'Try'
      ? routes.trialInformation.substring(1, routes.trialInformation.indexOf('/:'))
      : routes.buyerInformation.substring(1, routes.buyerInformation.indexOf('/:'));

  const locate = sessionStorage.getItem('locale') === 'en' ? sessionStorage.getItem('locale') : 'ja';
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isXsScreen = useMediaQuery('(max-width:600px)');
  const tabs = [
    {
      title: t('purchaseStepInputMail'),
    },
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepInputInformationBuy'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];
  const tabsTrial = [
    {
      title: t('purchaseStepInputMail'),
    },
    {
      title: t('purchaseStepSendMail'),
    },
    {
      title: t('purchaseStepInputInformationTrail'),
    },
    {
      title: t('purchaseStepInputInformationPay'),
    },
    {
      title: t('purchaseStepConfirmLast'),
    },
    {
      title: t('purchaseStepPurchaseComplete'),
    },
  ];
  const handleGoBack = () => {
    navigate(routes.purchase);
  };

  const [codeError, setCodeError] = useState<string>('');
  const schema = yup.object({
    email: yup
      .string()
      .required(t('purchaseEmail.msg.requiredEmail'))
      .email(t('purchaseEmail.msg.notValidEmail'))
      .max(256, t('muserCreate.msg.requiredEmail')),
    reEmail: yup
      .string()
      .required(t('purchaseEmail.msg.requiredReEmail'))
      .oneOf([yup.ref('email')], t('purchaseEmail.msg.notMatchReEmail'))
      .max(256, t('muserCreate.msg.requiredEmail')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<FormInput> = async ({ email }) => {
    try {
      await start(
        inputEmail,
        { EmailRegister: email.trim(), Url: url, Languages: locate, SubjectMail: t('purchaseEmail.subjectMail') },
        dispatch
      );
      navigate(routes.sendConfirmEmail);
    } catch (error: any) {
      let err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[{}]/g, '').trim();
      if (err.split('=').length > 1) {
        err = err.split('=')[1].trim();
      }
      if (err === 'purchaseEmail.emailRegistered') {
        setCodeError(t('purchaseEmail.emailRegistered'));
      } else if (!Number.isNaN(err)) {
        setCodeError(t(err, { url: `${MANAGE_LICENSE}/purchase` }));
      } else {
        setCodeError(t('purchaseEmail.systemError'));
      }
    }
  };

  // event change language, turn off errors message
  const [showErrors, setShowErrors] = useState(true);
  useEffect(() => {
    const handleChangeLanguage = () => {
      setShowErrors(false);
    };

    i18n.on('languageChanged', handleChangeLanguage);
    return () => {
      i18n.off('languageChanged', handleChangeLanguage);
    };
  }, [i18n]);

  // show errors when click button submit
  const handleFormSubmit = () => {
    setShowErrors(true);
    handleSubmit(onSubmit)();
  };

  return (
    <div className="login__container">
      <PurchaseForm className="purchase__inputEmail" enablePadding>
        <StepPurchase tabs={queryParams.get('purchase') === 'Try' ? tabsTrial : tabs} idx={0} />
        <TGGrid container columns={12} mt={8}>
          <TGGrid item sm={12}>
            {showErrors && codeError && (
              <Alert severity="error" icon={false}>
                <div className="content" dangerouslySetInnerHTML={{ __html: codeError }} />
              </Alert>
            )}
          </TGGrid>
          <TGGrid item sm={6} md={4} xl={3} xs={12} mt={2} display="flex" alignItems="center">
            <Typography>{t('purchaseEmail.label.inputEmail')}</Typography>
          </TGGrid>
          <TGGrid item sm={6} md={4} xl={3} xs={12} mt={2}>
            <TGTextField
              fullWidth
              required
              autoComplete="email"
              autoFocus
              id="outlined-basic"
              label=""
              variant="outlined"
              registration={register('email')}
              error={showErrors ? 'email' in errors : false}
              helperText={showErrors ? errors.email?.message : ''}
            />
          </TGGrid>
        </TGGrid>
        <TGGrid container columns={12}>
          <TGGrid item sm={6} md={4} xl={3} xs={12} mt={2} display="flex" alignItems="center">
            <Typography>{t('purchaseEmail.label.retypeEmail')}</Typography>
          </TGGrid>
          <TGGrid item sm={6} md={4} xl={3} xs={12} mt={2}>
            <TGTextField
              fullWidth
              required
              id="outlined-basic"
              label=""
              variant="outlined"
              registration={register('reEmail')}
              error={showErrors ? 'reEmail' in errors : false}
              helperText={showErrors ? errors.reEmail?.message : ''}
            />
          </TGGrid>
        </TGGrid>
        <Typography mt={5}>{t('purchaseEmail.label.tutorial1')}</Typography>
        <TGGrid container columns={12} mt={2}>
          <TGGrid item sm={6} md={5} xl={4} xs={12}>
            <Typography>{t('purchaseEmail.label.tutorial2')}</Typography>
          </TGGrid>
          <TGGrid item sm={6} md={4} xl={3} xs={12} sx={{ marginTop: isXsScreen ? '20px' : '0' }}>
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                sx={{ marginLeft: '20px', width: '120px', fontSize: '14px' }}
                className="purchase-button-gray"
                onClick={handleGoBack}
              >
                {t('purchaseEmail.button.cancel')}
              </Button>
              <Button
                variant="contained"
                sx={{ marginLeft: '10%', width: '120px', fontSize: '14px' }}
                onClick={handleFormSubmit}
              >
                {t('purchaseEmail.button.sendEmail')}
              </Button>
            </Box>
          </TGGrid>
        </TGGrid>
      </PurchaseForm>
    </div>
  );
}
