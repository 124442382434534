// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class PurchaseService {
  static sendEmail(params: any) {
    const url = 'purchase/create';
    return axiosClient.post(url, params);
  }

  static getInformationBuyer(params: any) {
    const url = 'purchase/informationBuyer/detail';
    return axiosClient.get(url, { params });
  }

  static inputInformationBuyer(params: any) {
    const url = 'purchase/register';
    return axiosClient.post(url, params);
  }

  static inputInformationTrial(params: any) {
    const url = 'purchase/registerTrial';
    return axiosClient.post(url, params);
  }

  static getFormInformationTrial(params: any) {
    const url = 'token/purchase/inputInformationTrial';
    return axiosClient.get(url, { params });
  }

  static infoTrialSuccess(params: any) {
    const url = 'purchase/buyerSuccess/detail';
    return axiosClient.get(url, { params });
  }

  static inputInfoBuyerSuccess(params: any) {
    const url = 'purchase/paymentSuccess';
    return axiosClient.get(url, { params });
  }

  static sendMailInvoice(params: any) {
    const url = 'purchase/sendMailInvoice';
    return axiosClient.get(url, { params });
  }

  static buyAdditionalLicenses(params: any) {
    const url = 'purchase/addLicense';
    return axiosClient.post(url, params);
  }

  static createInvoiceBankTransfer(params: any) {
    const url = 'purchase/bankTransfer';
    return axiosClient.post(url, params);
  }

  static CheckIsReadInfo(params: any) {
    const url = 'purchase/checkByTemporaryId';
    return axiosClient.post(url, params);
  }
}
export default PurchaseService;
