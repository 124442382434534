import { Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, RootStateOrAny } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PDFExport } from '@progress/kendo-react-pdf';
import { start } from '../../lib/saga-promise';
import TGGrid from '../../component/Elements/TGGrid';
import PurchaseForm from '../../component/Layout/PurchaseForm';
import InformationCompany from './info/informationCompany';
import InformationUser from './info/informationUser';
import { GetInformationTrial } from '../../state/actions/purchase';
import routes from '../../constants/routes';


function InformationTrial() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const param = useParams();
  const buyerDetail = useSelector((state: RootStateOrAny) => state.purchase.info);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  window.addEventListener('popstate', (event) => {
    window.location.href = routes.purchase;
  });

  const [flagShowLayout, setFlagShowLayout] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (param.id) {
        await start(GetInformationTrial, { infoId: param.id }, dispatch);
        setFlagShowLayout(true);
      }
    };
    fetchData();
  }, [dispatch, param.id]);

  const [layoutAllLicense, setlayoutAllLicense] = useState(false);
  const pdfExportComponent = useRef<PDFExport>(null);

  const handlePrintPdf = async () => {
    const input = document.getElementById('divToPrint');
    const btnPrint = document.getElementById('btn_print');
    if (btnPrint) {
      btnPrint.style.display = 'none';
      setlayoutAllLicense(true);
    }
    if (input && pdfExportComponent.current) {
      await new Promise((resolve) => {
        pdfExportComponent.current?.save(() => {
          resolve(true);
        });
      });

      if (btnPrint) {
        btnPrint.style.display = 'block';
        setlayoutAllLicense(false);
      }
    }
  };

  if (flagShowLayout) {
    return (
      <div className="purchase__container">
        <PDFExport
          ref={pdfExportComponent}
          paperSize="A4"
          scale={0.5}
          margin={40}
          fileName="tgd_start.pdf"
          author="KendoReact Team"
        >
          <PurchaseForm classId="divToPrint">
            <TGGrid container columns={12} mt={1} direction="row" justifyContent="start" alignItems="center">
              <TGGrid item xs={3} display="flex" alignItems="center">
                <Typography variant="h3" className="font-NotoSansJP">{t('purchaseBuyerInformation.success.label.top')}</Typography>
              </TGGrid>
              <TGGrid item xs={9} display="flex" justifyContent="end">
                <Button
                  variant="contained"
                  id="btn_print"
                  sx={{ width: '120px', fontSize: '14px' }}
                  onClick={handlePrintPdf}
                  className="font-NotoSansJP"
                >
                  {t('purchaseBuyerInformation.success.button.print')}
                </Button>
              </TGGrid>
            </TGGrid>
            <Typography variant="h5" mt={2} mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
              {t('purchaseBuyerInformation.success.label.description1')}
            </Typography>
            <Typography variant="h5" mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
              {t('purchaseBuyerInformation.success.label.description2')}
            </Typography>
            <Typography variant="h5" mb={1} color="inherit" className="fs-09-rem font-NotoSansJP">
              {t('purchaseBuyerInformation.success.label.description3')}
            </Typography>
            {buyerDetail.licensePurchaser === '0' ? (
              <InformationCompany buyerDetail={buyerDetail} layoutAllLicense={layoutAllLicense} />
            ) : (
              <InformationUser buyerDetail={buyerDetail} />
            )}
          </PurchaseForm>
        </PDFExport>
      </div>
    );
  }
  return <div className="waiting" />;
}

export default InformationTrial;
