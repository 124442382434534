import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  Grid,
  Alert,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  Card,
  CardContent,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link } from 'react-router-dom';
import { remind } from '../../state/actions/auth';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import Logo from '../layout/main/header/logo';

interface FormInput {
  loginId: string;
}

export default function Remind() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const [codeError, setCodeError] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const schema = yup.object({
    loginId: yup.string().required(t('remind.msg.userId.require')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const locate = sessionStorage.getItem('locale') === 'en' ? sessionStorage.getItem('locale') : 'ja';

  const onSubmit: SubmitHandler<FormInput> = async ({ loginId }) => {
    try {
      await start(remind, { loginId: loginId.trim(), Languages: locate }, dispatch);
      setCodeError('');
      setIsSuccess(true);
    } catch (error: any) {
      if (error.data?.code) {
        setCodeError(t(error.data.code));
      } else if (error.data?.message) {
        setCodeError(t(error.data.message));
      } else {
        setCodeError(
          t('remind.msg.userId.notFound')
        );
      }
    }
  };

  return (
    <div className="login__container">
      <Card className="login__card">
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
            <Logo />
          </Box>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography color={theme.palette.primary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
              {t('remind.label.top')}
            </Typography>
          </Stack>
          {codeError && (
            <Alert severity="error" icon={false}>
              {codeError}
            </Alert>
          )}
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="center" mt={2}>
              {!isSuccess && (
                <Stack>
                  <Stack sx={{ mb: 3 }}>
                    <Typography>
                      {t('remind.label.description1')}
                      <br />
                      {t('remind.label.description2')}
                    </Typography>
                  </Stack>
                  <ListItemText
                    primary={(
                      <Typography variant="h5" color="inherit">
                        {t('remind.label.userIdOrEmail')}
                      </Typography>
                    )}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    autoComplete="loginId"
                    autoFocus
                    sx={{ mt: 0 }}
                    {...register('loginId')}
                    error={'loginId' in errors}
                    helperText={errors.loginId?.message}
                  />

                  <Box sx={{ mt: 2 }}>
                    <Button
                      disableElevation
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={handleSubmit(onSubmit)}
                    >
                      {t('common.btn.send')}
                    </Button>
                  </Box>
                </Stack>
              )}
              {isSuccess && (
                <Stack>
                  <Stack sx={{ mb: 3 }}>
                    <Typography>
                      {t('remind.label.sendResult1')}
                      <br />
                      {t('remind.label.sendResult2')}
                    </Typography>
                  </Stack>
                </Stack>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 3 }}>
            <Typography variant="body1" gutterBottom mt={2} sx={{ textAlign: 'left' }}>
              <Link to={routes.login}>{t('remind.link.returnLogin')}</Link>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
