import { call, put, takeLatest } from 'redux-saga/effects';
import PriceLicenseServices from '../../services/price';
import {
  types,
  getAllPriceLicenseSuccess,
} from '../actions/price';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Iaction {
  type: string;
  payload: any;
}

function* getAllPriceLicense(action: Iaction) {
  try {
    const data: ResponseGenerator = yield call(PriceLicenseServices.getAllPriceLicense, action.payload);
    yield put(getAllPriceLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getPriceLicense(action: Iaction) {
  try {
    const data: ResponseGenerator = yield call(PriceLicenseServices.getPriceLicense, action.payload);
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* priceLicenseSaga() {
  yield takeLatest(types.GET_ALL_PRICE_LICENSE, sagaPromise(getAllPriceLicense));
  yield takeLatest(types.GET_PRICE_LICENSE, sagaPromise(getPriceLicense));
}
