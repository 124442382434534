/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, UseFormReturn, UseFormSetValue } from 'react-hook-form';
import * as yup from 'yup';
import { creatUser, editUser } from '../../state/actions/user';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import GroupSelector from '../../component/Form/GroupSelector';
import { getGroups } from '../../state/actions/group';
import RegisterForm from '../../component/Layout/RegisterForm';
import { RegisterInput, schemaEdit } from './types';
import TGTextField from '../../component/Elements/TGTextField';
import IsInvalidSwitch from '../../component/Form/IsInvalidSwitch';
import CountrySelector from '../../component/Form/CountrySelector';
import { getCountries } from '../../state/actions/country';
import hasRole from '../../lib/hasRole';
import TGGrid from '../../component/Elements/TGGrid';
import { groupPasswordPolicyDetail } from '../../state/actions/group_password_policy';
import { getRegExpSchemaPasswordPolicy } from '../../component/helpers/utility';
import RoleSelector from '../../component/Form/RoleSelector';
import { USER_ROLE } from '../../constants/app';
import TGTextarea from '../../component/Elements/TGTextarea';

export default function UserCreate(_userDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_userDetail).length > 0;
  // const schema = isEditMode ? schemaEdit : schemaNew;
  const [schema, setSchema] = useState(yup.object());
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const countries = useSelector((state: RootStateOrAny) => state.country.allCountries);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const isUserRoleGroupAdmin = hasRole('groupAdmin', user);
  const isUserRoleUser = hasRole('user', user);
  const isUserRoleSystemAdmin = hasRole('systemAdmin', user);
  const detail = useSelector((state: RootStateOrAny) => state.user.user);
  const initialRender = useRef(true);
  const [method, setMethod] = useState<UseFormReturn<RegisterInput, object>>();
  const policyDetail = useSelector((state: RootStateOrAny) => state.group_password_policy.group_password_policy);

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
    start(getGroups, { noLoading: true }, dispatch);
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    handleChangePolicy(isEditMode ? _userDetail.groupId : isUserRoleGroupAdmin ? user.groupId : '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!initialRender.current && method) {
      // Display automatically generated user ID
      method.setValue('loginId', detail.loginId);
    } else {
      initialRender.current = false;
    }
  }, [detail, method]);

  useEffect(() => {
    const reg = getRegExpSchemaPasswordPolicy(
      policyDetail.isRequiredLowerAlphabetChars,
      policyDetail.isRequiredUpperAlphabetChars,
      policyDetail.isRequiredNumericChars,
      policyDetail.isRequiredMarkChars
    );
    let validateMsg = policyDetail.isRequiredLowerAlphabetChars ? t('PasswordLowerAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredUpperAlphabetChars ? t('PasswordUpperAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredNumericChars ? t('PasswordNumericChars') : '';
    validateMsg += policyDetail.isRequiredMarkChars ? t('PasswordMarkChars') : '';
    let schemaNew: any;
    if (isUserRoleSystemAdmin || (!isEditMode && isUserRoleGroupAdmin)) {
      if (validateMsg === '') {
        schemaNew = schemaEdit.concat(
          yup.object({
            password: yup
              .string()
              .required('muserCreate.msg.requiredPassword')
              .min(policyDetail.minimumLength, t('Password107-2').replace('{1}', policyDetail.minimumLength))
              .max(127, 'muserCreate.msg.requiredPassword'),
          })
        );
      } else {
        schemaNew = schemaEdit.concat(
          yup.object({
            password: yup
              .string()
              .required('muserCreate.msg.requiredPassword')
              .min(
                policyDetail.minimumLength,
                t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)
              )
              .max(127, 'muserCreate.msg.requiredPassword')
              .matches(reg, t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)),
          })
        );
      }
    }
    if (isUserRoleSystemAdmin) {
      schemaNew = schemaEdit.concat(
        yup.object({
          groupId: yup
            .string()
            .typeError('muserCreate.msg.requiredGroup')
            .required('muserCreate.msg.requiredGroup'),
          postcode: yup
            .string()
            .required('groupCreate.msg.requiredPostcodeAdmin')
            .length(7, 'groupCreate.msg.requiredPostcodeAdmin')
            .matches(/^[0-9]+$/, ('purchase.msg.requiredZipCode')),
          address: yup
            .string()
            .trim()
            .required('purchase.msg.requiredAddress')
            .max(256, 'purchase.msg.requiredAddress'),
          phoneNumber: yup
            .string()
            .trim()
            .required('purchase.msg.requiredTelephoneNumber')
            .max(11, 'purchase.msg.maxphoneNumber')
            .matches(/^[0-9]+$/, 'purchase.msg.wrongTelephoneNumber')
            .min(10, t('purchase.msg.requiredTelephoneNumber')),
          profession: yup
            .string()
            .trim()
            .required('purchase.msg.requiredProfession')
            .max(256, 'purchase.msg.requiredProfession'),
          workplace: yup
            .string()
            .trim()
            .required('purchase.msg.requiredWorkplace')
            .max(256, 'purchase.msg.requiredWorkplace'),
          comment: yup.string().trim().notRequired().max(1024, 'purchase.msg.maxComment'),
        })
      );
      if (validateMsg === '') {
        schemaNew = schemaNew.concat(
          yup.object({
            password: yup
              .string()
              .required('muserCreate.msg.requiredPassword')
              .min(policyDetail.minimumLength, t('Password107-2').replace('{1}', policyDetail.minimumLength))
              .max(127, 'muserCreate.msg.requiredPassword'),
          })
        );
      } else {
        schemaNew = schemaNew.concat(
          yup.object({
            password: yup
              .string()
              .required('muserCreate.msg.requiredPassword')
              .min(
                policyDetail.minimumLength,
                t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)
              )
              .max(127, 'muserCreate.msg.requiredPassword')
              .matches(reg, t('Password107-1').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)),
          })
        );
      }
    }
    let schemaEditAdmin: any;
    // eslint-disable-next-line prefer-const
    schemaEditAdmin = schemaEdit.concat(
      yup.object({
        postcode: yup
          .string()
          .typeError('groupCreate.msg.requiredPostcodeAdmin')
          .required('groupCreate.msg.requiredPostcodeAdmin')
          .length(7, 'groupCreate.msg.requiredPostcodeAdmin')
          .matches(/^[0-9]+$/, ('purchase.msg.requiredZipCode')),
        groupId: yup
          .string()
          .typeError('muserCreate.msg.requiredGroup')
          .required('muserCreate.msg.requiredGroup'),
        phoneNumber: yup
          .string()
          .trim()
          .typeError('purchase.msg.requiredTelephoneNumber')
          .required('purchase.msg.requiredTelephoneNumber')
          .max(11, 'purchase.msg.maxphoneNumber')
          .matches(/^[0-9]+$/, 'purchase.msg.wrongTelephoneNumber')
          .min(10, ('purchase.msg.requiredTelephoneNumber')),
        address: yup
          .string()
          .trim()
          .typeError('purchase.msg.requiredAddress')
          .required('purchase.msg.requiredAddress')
          .max(256, 'purchase.msg.requiredAddress'),
        profession: yup
          .string()
          .trim()
          .typeError('purchase.msg.requiredProfession')
          .required('purchase.msg.requiredProfession')
          .max(256, 'purchase.msg.requiredProfession'),
        workplace: yup
          .string()
          .trim()
          .typeError('purchase.msg.requiredWorkplace')
          .required('purchase.msg.requiredWorkplace')
          .max(256, 'purchase.msg.requiredWorkplace'),
      })
    );

    setSchema(isEditMode ? (isUserRoleSystemAdmin ? schemaEditAdmin : schemaEdit) : schemaNew);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditMode, policyDetail, t]);

  const handleSubmit = async (
    data: UnpackNestedValue<RegisterInput>,
    _method: UseFormReturn<RegisterInput, object>
  ) => {
    if (isEditMode) {
      const editData = {
        ...data,
        userId: _userDetail.userId,
        loginId: _userDetail.loginId,
      };
      await start(editUser, editData, dispatch);
    } else {
      data.loginId = '';
      await start(creatUser, data, dispatch);
      setMethod(_method);
    }
  };
  const handleChangePolicy = (groupId: string | undefined) => {
    start(groupPasswordPolicyDetail, { groupId }, dispatch);
  };
  const handleChangeGroup = (_groupId: string | undefined, setValue: UseFormSetValue<RegisterInput>) => {
    setValue('groupId', _groupId !== undefined ? _groupId : '');
    handleChangePolicy(_groupId);
  };

  if (!isEditMode && !isUserRoleSystemAdmin && !isUserRoleGroupAdmin) {
    return null;
  }

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('userCreate.label.top')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.userSearch)}
      options={{
        defaultValues: {
          loginId: isEditMode ? _userDetail.loginId : t('userCreate.placeHolder.UserId'),
          groupId: isEditMode ? _userDetail.groupId : isUserRoleGroupAdmin ? user.groupId : '',
          username: isEditMode ? _userDetail.username : '',
          email: isEditMode ? _userDetail.email : '',
          nickname: isEditMode ? _userDetail.nickname : '',
          countryId: isEditMode ? _userDetail.countryId : '',
          roleId: isEditMode ? _userDetail.roleId : roles.user,
          furigana: isEditMode ? _userDetail.furigana : '',
          postcode: isEditMode ? _userDetail.postcode : '',
          address: isEditMode ? _userDetail.address : '',
          phoneNumber: isEditMode ? _userDetail.phoneNumber : '',
          profession: isEditMode ? _userDetail.profession : '',
          workplace: isEditMode ? _userDetail.workplace : '',
          comment: isEditMode ? _userDetail.comment : '',
        },
      }}
    >
      {({ register, setValue, formState: { errors }, watch }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={12}>
            <TGTextField
              label={t('common.label.userid')}
              isDisabled
              registration={register('loginId')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <GroupSelector
              groups={groups}
              groupId={watch('groupId')}
              isDisable={isUserRoleGroupAdmin || isUserRoleUser}
              handleChange={(data) => handleChangeGroup(data?.groupId, setValue)}
              error={'groupId' in errors}
              message={errors.groupId?.message}
            />
          </TGGrid>
          {(isUserRoleSystemAdmin || (!isEditMode && isUserRoleGroupAdmin)) && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                label={t('common.label.password')}
                type="text"
                registration={register('password')}
                isError={'password' in errors}
                errorMessage={errors.password?.message}
              />
            </TGGrid>
          )}
          <TGGrid item xs={12} sm={6}>
            <CountrySelector
              countries={countries}
              countryId={watch('countryId')}
              handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
              error={'countryId' in errors}
              message={errors.countryId?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.username')}
              registration={register('username')}
              isError={'username' in errors}
              errorMessage={errors.username?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.furigana')}
              registration={register('furigana')}
              isError={'furigana' in errors}
              errorMessage={errors.furigana?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.email')}
              type="email"
              registration={register('email')}
              isError={'email' in errors}
              errorMessage={errors.email?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.nickname')}
              registration={register('nickname')}
              isError={'nickname' in errors}
              errorMessage={errors.nickname?.message}
            />
          </TGGrid>
          {(isUserRoleSystemAdmin || user.isPersonalMng || isUserRoleUser) && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                label={t('common.label.postcode')}
                registration={register('postcode')}
                isError={'postcode' in errors}
                errorMessage={errors.postcode?.message}
              />
            </TGGrid>
          )}
          {(isUserRoleSystemAdmin || user.isPersonalMng || isUserRoleUser) && (
            <TGGrid container item xs={12} sm={6} alignItems="center">
              <TGTextField
                label={t('common.label.address')}
                registration={register('address')}
                isError={'address' in errors}
                errorMessage={errors.address?.message}
              />
            </TGGrid>
          )}
          {(isUserRoleSystemAdmin || user.isPersonalMng || isUserRoleUser) && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                inputProps={{
                  maxLength: 11,
                }}
                label={t('common.label.phoneNumber')}
                registration={register('phoneNumber')}
                isError={'phoneNumber' in errors}
                errorMessage={errors.phoneNumber?.message}
              />
            </TGGrid>
          )}
          {(isUserRoleSystemAdmin || user.isPersonalMng || isUserRoleUser) && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                label={t('purchaseProfession')}
                registration={register('profession')}
                isError={'profession' in errors}
                errorMessage={errors.profession?.message}
              />
            </TGGrid>
          )}
          {(isUserRoleSystemAdmin || user.isPersonalMng || isUserRoleUser) && (
            <TGGrid item xs={12} sm={6}>
              <TGTextField
                label={t('purchaseWorkplace')}
                registration={register('workplace')}
                isError={'workplace' in errors}
                errorMessage={errors.workplace?.message}
              />
            </TGGrid>
          )}
          {isUserRoleSystemAdmin && (
            <TGGrid item xs={12} sm={6}>
              <RoleSelector
                roles={USER_ROLE}
                handleChange={(event) => setValue('roleId', event.target.value)}
                roleId={watch('roleId')}
                hasBlank
              />
            </TGGrid>
          )}

          <TGGrid item xs={12} sm={6} userRoleId={user.roleId} showRoles={[roles.systemAdmin, roles.groupAdmin]}>
            <IsInvalidSwitch
              defaultChecked={!!(isEditMode && _userDetail.isInvalid)}
              registration={register('isInvalid')}
            />
          </TGGrid>
          {isUserRoleSystemAdmin && (
            <TGGrid item xs={12} sm={12}>
              <TGTextarea
                placeholder={t('csvFormat.label.description')}
                registration={register('comment')}
                minRows={12}
                disabled={false}
              />
            </TGGrid>
          )}
        </TGGrid>
      )}
    </RegisterForm>
  );
}
