import * as yup from 'yup';
import moment from 'moment';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  versionNumber: string;
  versionReleaseDate: string;
  versionReleaseNote: string;
  versionReleaseNoteEngVer: string;
  versionRegisterPerson : string;
  versionRegisterDate : string;
  titleJaVer: string;
  titleEngVer: string;
};
const versionRegExp = /^\d+\.\d+\.\d+$/;
const versionReleaseNoteRegExp = /[^\s*].*[^\s*]/;
export const schemaEdit = yup.object({
  titleJaVer: yup
    .string().typeError('versionCreate.msg.requiredVersionNumber')
    .max(64, 'versionCreate.err.requiredVersionNumberLength'),
  titleEngVer: yup
    .string().typeError('versionCreate.msg.requiredVersionNumber')
    .max(64, 'versionCreate.err.requiredVersionNumberLength'),
  versionNumber: yup
    .string().typeError('versionCreate.msg.requiredVersionNumber')
    .required('versionCreate.msg.requiredVersionNumber')
    .matches(versionRegExp, 'versionCreate.err.requiredVersionNumberFormat')
    .max(64, 'versionCreate.err.requiredVersionNumberLength'),
  versionReleaseDate: yup
    .date()
    .typeError('versionCreate.msg.formatDate')
    .required('versionCreate.msg.requiredStartDate')
    .min('2000/01/01', 'versionCreate.msg.formatDate')
    .max('2999/12/31', 'versionCreate.msg.formatDate'),
  versionReleaseNote: yup
    .string()
    .typeError('versionCreate.msg.requiredVersionReleaseNote')
    .required('versionCreate.msg.requiredVersionReleaseNote')
    .matches(versionReleaseNoteRegExp, 'versionCreate.msg.requiredVersionReleaseNote')
    .max(1052, 'versionCreate.err.requiredVersionReleaseNoteLength'),
  versionReleaseNoteEngVer: yup
    .string()
    .typeError('versionCreate.msg.requiredVersionReleaseNote')
    .required('versionCreate.msg.requiredVersionReleaseNote')
    .matches(versionReleaseNoteRegExp, 'versionCreate.msg.requiredVersionReleaseNote')
    .max(1052, 'versionCreate.err.requiredVersionReleaseNoteLength'),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'versionNumberId'
    | 'versionNumber'
    | 'titleEngVer'
    | 'versionReleaseDate'
    | 'titleJaVer'
    | 'versionReleaseNote'
    | 'versionReleaseNoteEngVer'
    | 'versionRegisterPerson'
    | 'versionRegisterTime'
    | 'cancelAction'
    | 'versionCancelDate'
    | 'resetCancelAction'
};

export const columns: Column[] = [
  {
    id: 'versionNumberId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'versionNumber',
    label: 'versionCreate.label.licenseRegist',
    align: 'center',
  },
  {
    id: 'versionReleaseDate',
    label: 'versionCreate.label.releaseDate',
    format: (value: Date) => moment(value).local().format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'titleJaVer',
    label: 'versionCreate.label.titleJaVer',
    align: 'left',
  },
  {
    id: 'titleEngVer',
    label: 'versionCreate.label.titleEnVer',
    align: 'left',
  },
  {
    id: 'versionRegisterPerson',
    label: 'versionCreate.label.registeredPerson',
    align: 'left',
  },
  {
    id: 'versionRegisterTime',
    label: 'versionCreate.label.registeredDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'cancelAction',
    label: 'versionSearch.label.cancel',
    align: 'center',
  },
  {
    id: 'versionCancelDate',
    label: 'versionSearch.label.versionCancelDate',
    format: (value: Date | null) => (value ? moment(value).format('YYYY/MM/DD') : ''),
    align: 'center',
  },
  {
    id: 'resetCancelAction',
    label: 'versionSearch.label.resetCancelAction',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  releaseDateFrom: string;
  releaseDateTo: string;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  releaseDateFrom: '',
  releaseDateTo: '',
  pageIndex: DEFAULT_PAGE,
};
