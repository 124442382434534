import { types } from '../actions/group_password_policy';

interface GroupPasswordPolicyRequest {
  type: string;
  payload?: any;
}

type GroupPasswordPolicyActions = GroupPasswordPolicyRequest;

interface IGroupPasswordPolicyState {
  group_password_policy: any;
  error: string | null;
}

const initialState: IGroupPasswordPolicyState = {
  group_password_policy: [],
  error: null,
};
// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: GroupPasswordPolicyActions) => {
  switch (type) {
    case types.EDIT_GROUP_PASSWORD_POLICY_SUCCESS: {
      state.group_password_policy = payload;
      return {
        ...state,
      };
    }
    case types.GROUP_PASSWORD_POLICY_DETAIL_SUCCESS:
      state.group_password_policy = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
