export const types = {
  GET_ALL_PRICE_LICENSE: 'GET_ALL_PRICE_LICENSE',
  GET_ALL_PRICE_LICENSE_SUCCESS: 'GET_ALL_PRICE_LICENSE_SUCCESS',
  GET_PRICE_LICENSE: 'GET_PRICE_LICENSE',
  GET_PRICE_LICENSE_SUCCESS: 'GET_PRICE_LICENSE_SUCCESS',
};

export const getAllPriceLicense = (payload: any) => ({
  type: types.GET_ALL_PRICE_LICENSE,
  payload,
});

export const getAllPriceLicenseSuccess = (payload: any) => ({
  type: types.GET_ALL_PRICE_LICENSE_SUCCESS,
  payload,
});

export const getPriceLicense = (payload: any) => ({
  type: types.GET_PRICE_LICENSE,
  payload,
});

export const getPriceLicenseSuccess = (payload: any) => ({
  type: types.GET_PRICE_LICENSE_SUCCESS,
  payload,
});
