/* eslint-disable max-len */
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, UseFormReturn } from 'react-hook-form';
import moment from 'moment';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import GroupSelector from '../../component/Form/GroupSelector';
import { getGroups } from '../../state/actions/group';
import RegisterForm from '../../component/Layout/RegisterForm';
import { RegisterInput, schemaEdit, schemaNew } from './types';
import TGTextField from '../../component/Elements/TGTextField';
import IsInvalidSwitch from '../../component/Form/IsInvalidSwitch';
import TGGrid from '../../component/Elements/TGGrid';
import { createLicense, editLicense } from '../../state/actions/license';
import TGTextarea from '../../component/Elements/TGTextarea';

export default function LicenseCreate(_userDetail: any = null) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const isEditMode: boolean = Object.keys(_userDetail).length > 0;
  const schema = isEditMode ? schemaEdit : schemaNew;
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const detail = useSelector((state: RootStateOrAny) => state.license.license);
  const initialRender = useRef(true);
  const [method, setMethod] = useState<UseFormReturn<RegisterInput, object>>();

  const [isLicenseEnable, setLicenseEnable] = useState(!!(isEditMode && _userDetail.isInvalid));

  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
  }, [dispatch]);

  useEffect(() => {
    if (!initialRender.current && method) {
      method.setValue('licenseCode', detail.licenseCode);
    } else {
      initialRender.current = false;
    }
  }, [detail, method]);

  const handleSubmit = async (
    data: UnpackNestedValue<RegisterInput>,
    _method: UseFormReturn<RegisterInput, object>
  ) => {
    if (data.startDate) {
      const startDateObj = new Date(data.startDate);
      startDateObj.setHours(23);
      startDateObj.setMinutes(59);
      data.startDate = startDateObj.toISOString();
    }
    if (data.endDate) {
      const endDateObj = new Date(data.endDate);
      endDateObj.setHours(23);
      endDateObj.setMinutes(59);
      data.endDate = endDateObj.toISOString();
    }
    if (isEditMode) {
      const editData = {
        ...data,
        LicenseId: _userDetail.licenseId,
      };
      await start(editLicense, editData, dispatch);
    } else {
      data.licenseCode = '';
      await start(createLicense, data, dispatch);
      setMethod(_method);
    }
  };
  const purchaseType = [
    {
      groupId: 'Paid',
      groupCode: '',
      groupName: t('licenseStatusContracted'),
    },
    {
      groupId: 'Trial',
      groupCode: '',
      groupName: t('licenseStatusFreeTrial'),
    },
  ];

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('licenseCreate.label.top')}
      isEditMode={isEditMode}
      handleCancel={() => history(routes.licenseSearch)}
      options={{
        defaultValues: {
          groupId: isEditMode ? _userDetail.groupId : null,
          licenseCode: isEditMode ? _userDetail.licenseCode : t('licenseCreate.placeHolder.licenseNo'),
          availableDeviceNumber: isEditMode ? _userDetail.availableDeviceNumber : '',
          purchaseType: isEditMode ? _userDetail.purchaseType : null,
          startDate: isEditMode ? moment(_userDetail.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'),
          endDate: isEditMode
            ? moment(_userDetail.endDate).format('YYYY-MM-DD')
            : moment().add(1, 'years').subtract(1, 'days').format('YYYY-MM-DD'),
        },
      }}
    >
      {({ register, setValue, formState: { errors }, watch }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={12}>
            <TGTextField label={t('common.label.licenseCode')} isDisabled registration={register('licenseCode')} />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <GroupSelector
              groups={groups}
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              error={'groupId' in errors}
              message={errors.groupId?.message}
              isDisable={isEditMode}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.availableDeviceNumber')}
              registration={register('availableDeviceNumber')}
              isError={'availableDeviceNumber' in errors}
              errorMessage={errors.availableDeviceNumber?.message}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.startDate')}
              type="date"
              registration={register('startDate')}
              isError={'startDate' in errors}
              errorMessage={errors.startDate?.message}
              disabled={isEditMode}
            />
          </TGGrid>

          <TGGrid item xs={12} sm={6}>
            <TGTextField
              label={t('common.label.endDate')}
              type="date"
              registration={register('endDate')}
              isError={'endDate' in errors}
              errorMessage={errors.endDate?.message}
              disabled={isEditMode}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <GroupSelector
              groupId={watch('purchaseType')}
              handleChange={(data) => setValue('purchaseType', data?.groupId ?? '')}
              groups={purchaseType}
              label={t('common.label.licenseStatus')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={6}>
            <IsInvalidSwitch
              onChange={(e) => setLicenseEnable(e)}
              defaultChecked={!!(isEditMode && _userDetail.isInvalid)}
              registration={register('isInvalid')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={12}>
            <TGTextarea registration={register('comment')} disabled={!isLicenseEnable} minRows={12} />
          </TGGrid>
        </TGGrid>
      )}
    </RegisterForm>
  );
}
