// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class PriceLicenseServices {
  static getAllPriceLicense(params: any) {
    const url = 'price/list';
    return axiosClient.get(url, params);
  }

  static getPriceLicense(params: any) {
    const url = 'price';
    return axiosClient.get(url, params);
  }
}
export default PriceLicenseServices;
